import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col, Form, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Search } from "react-feather";
import moment from "moment";
import { sort, subString } from "../../../utils/util";
import "antd/dist/antd.css";
import { Table } from "antd";
import { useForm } from "react-hook-form";

import Tag from "../../common/Tag";
import Breadcrumb from "../../common/breadcrumb";
import { getAllCases } from "../../../services/neutral_api";

const YourCases = () => {
  const history = useHistory();
  const { register, handleSubmit, reset, getValues } = useForm();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const listCaseFiles = async (params) => {
    setLoading(true);
    const res = await getAllCases(params);
    if (res) {
      if (res.status) {
        setPagination({
          current: params.page,
          pageSize: params.per_page,
          total: res.total_entries,
        });
        setDataSource(res.data);
        setLoading(false);
      } else setLoading(false);
    }
  };

  useEffect(() => {
    listCaseFiles({ page: pagination.current, per_page: pagination.pageSize });
  }, []);

  function onPagination(page, filters, sorter, extra) {
    setPagination({
      ...page,
    });
    let params = {
      page: page.current,
      per_page: page.pageSize,
    };
    if (sorter.order) {
      params.order = `${sorter.column.key} ${
        sorter.order === "descend" ? "desc" : ""
      }`;
    }
    if (filters.process) {
      params.process = `${filters.process}`;
    }
    if (filters.status) {
      params.status = `${filters.status}`;
    }

    listCaseFiles(params);
  }

  const handleSearch = (data) => {
    listCaseFiles({ query: data.search });
  };

  const clearFilter = () => {
    reset();
    listCaseFiles({
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: "Subject",
      key: "subject",
      fixed: "left",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: ({ subject }) => {
        return <strong>{subject}</strong>;
      },
      // ...getColumnSearchProps("subject"),
    },
    {
      title: "Background",
      key: "background",
      render: ({ background }) => {
        return subString(background, 50);
      },
    },
    {
      title: "Claims",
      key: "claims",
      render: ({ claims }) => {
        return subString(claims, 50);
      },
    },
    {
      title: "Case No",
      key: "case_no",
      render: ({ case_no }) => {
        if (case_no) {
          return case_no;
        } else return "-";
      },
    },
    {
      title: "Status",
      key: "status",
      filters: [
        {
          text: "Submitted",
          value: "submitted",
        },
        {
          text: "In Review",
          value: "in_review",
        },
        {
          text: "Accepted",
          value: "accepted",
        },
        {
          text: "Pending Payment",
          value: "pending_payment",
        },
        {
          text: "Paid",
          value: "paid",
        },
        {
          text: "Scheduled",
          value: "scheduled",
        },
        {
          text: "On Hold",
          value: "on_hold",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
        {
          text: "Closed",
          value: "closed",
        },
      ],
      filterMultiple: false,
      align: "center",
      render: ({ status }) => {
        return <Tag status={status} />;
      },
    },
    {
      title: "Process",
      key: "process",
      filters: [
        {
          text: "Arbitration",
          value: "arbitration",
        },
        {
          text: "Mediation",
          value: "mediation",
        },
        {
          text: "Conciliation",
          value: "conciliation",
        },
      ],
      filterMultiple: false,
      render: ({ process }) => {
        if (process) {
          return <span style={{ textTransform: "capitalize" }}>{process}</span>;
        } else return "-";
      },
    },
    {
      title: "Created At",
      key: "created_at",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: ({ created_at }) => {
        return moment(created_at).format("DD/MM/YYYY");
      },
    },
  ];

  return (
    <>
      <Breadcrumb title="Your Cases" />

      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Row className=" mb-3">
                  <Col lg={5} md={6} sm={12}>
                    <Form
                      onSubmit={handleSubmit(handleSearch)}
                      id="neutral_search"
                      className="validation d-flex align-items-center justify-content-between"
                    >
                      {/* <Row form>
                        <Col xl={8} md={8} sm={8} xs={8}> */}
                      <div className="d-flex align-items-center flex-grow-1">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          name="search"
                          ref={register()}
                          // onChange={(e) => setSearch(e.target.value)}
                        />

                        {/* </Col>
                        <Col xl={4} md={4} sm={4} xs={4}> */}
                        <Button color="light" type="submit" className="h-100">
                          <i class="fa fa-search mr-2"> </i>Search
                        </Button>
                      </div>

                      {/* </Col>
                      </Row> */}
                    </Form>
                  </Col>
                  <Col
                    lg={7}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-end"
                  >
                    {getValues("search") && getValues("search").length > 0 ? (
                      <Button color="primary" outline onClick={clearFilter}>
                        <i className="fa fa-times"></i> Clear Search
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <Table
                  rowClassName="pointer"
                  loading={loading}
                  columns={columns}
                  dataSource={dataSource}
                  onChange={onPagination}
                  pagination={pagination}
                  scroll={{ x: 800 }}
                  onRow={(r) => ({
                    onClick: () => history.push(`/neutral/cases/${r.id}`),
                  })}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default YourCases;
