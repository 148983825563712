export const hideEmail = function (email) {
  if (email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  }
};

export const subString = (content, char) => {
  if (content) {
    if (content.length < char) {
      return content.substring(0, char);
    } else {
      return `${content.substring(0, char)}...`;
    }
  }
};

export const sort = (type, dataIndex) => {
  if (type === "string") {
    return (a, b) => {
      if (a[dataIndex] && b[dataIndex]) {
        var nameA = a[dataIndex].toUpperCase();
        var nameB = b[dataIndex].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }

      // names must be equal
      return 0;
    };
  } else if (type === "number") {
    return (a, b) => a[dataIndex] - b[dataIndex];
  } else return false;
};

export const displayName = (fName, lName) => {
  if (!fName && !lName) return "N/A";
  if (fName && !lName) return fName;
  if (fName && lName) return fName + " " + lName;
  if (!fName && lName) return lName;
};

export const getColor = (status) => {
  switch (status) {
    case "scheduled":
      return "info";
    case "cancelled":
      return "danger";
    case "completed":
      return "success";
    case "rescheduled":
      return "warning";
    case "expired":
      return "danger";
    case "submitted":
      return "success";
    case "in_review":
      return "info";
    case "accepted":
      return "secondary";
    case "pending_payment":
      return "warning";
    case "on_hold":
      return "warning";
    case "rejected":
      return "danger";
    case "closed":
      return "danger";
    case "paid":
      return "secondary";
    case "created":
      return "info";
    case "partially_paid":
      return "warning";
    default:
      return;
  }
};

export const checkLoggedInUser = () => {
  const user = localStorage.getItem("user");
  const neutral = localStorage.getItem("neutral");
  if (user) return "user";
  if (neutral) return "neutral";
};
