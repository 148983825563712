import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { getComments } from "../../../services/user_api";
import moment from "moment";

import { displayName } from "../../../utils/util";
import Breadcrumb from "../../common/breadcrumb";
import { Pagination } from "antd";

const Comments = () => {
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState();
  const [perPage, setPerPage] = useState(10);

  const getAllComments = async (page, per_page) => {
    const res = await getComments(id, page, per_page);
    if (res) {
      if (res.status) {
        setComments(res.data);
        setTotalComments(res.total_entries);
      }
    }
  };

  useEffect(() => {
    getAllComments();
  }, []);

  return (
    <Container fluid>
      <Breadcrumb
        grand_parent="Cases"
        grand_parent_url="/user/cases"
        parent="Case Detail"
        parent_url={`/user/cases/${id}`}
        title="Comments"
      />
      <Card>
        <CardBody id="comment">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <h5 className="mb-3">Comments</h5>
              <p className="text-black-50 ">{totalComments} Comments</p>
            </div>
            <div className="comments-container">
              <ul className="list">
                {comments.map((c) => {
                  if (c.status !== "blocked") {
                    return (
                      <li className="list-item mb-4">
                        <div className="media-body mt-0 d-flex justify-content-between">
                          <h6 className="mb-0 f-w-700">
                            {c.title}
                            <span className="time">
                              {moment(c.created_at).from(moment())}
                            </span>
                          </h6>
                          <>
                            <p>
                              -
                              {displayName(
                                c.created_by.first_name,
                                c.created_by.last_name
                              )}
                            </p>
                          </>
                        </div>
                        <p className="mb-1">{c.content}</p>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
      <Pagination
        total={totalComments}
        onChange={(page) => getAllComments(page, perPage)}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={perPage}
        defaultCurrent={1}
      />
    </Container>
  );
};

export default Comments;
