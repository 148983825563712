import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Label,
  FormGroup,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import { User } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Tag from "../../common/Tag";
import Avatar from "../../../assets/images/avatar.jpg";
import Breadcrumb from "../../common/breadcrumb";
import { getColor, displayName, subString } from "../../../utils/util";
import config from "../../../utils/config.json";
import {
  caseDetail,
  getComments,
  addComment,
} from "../../../services/neutral_api";
import HearingModal from "../../common/HearingModal";
import { Skeleton } from "antd";
import "antd/lib/skeleton/style/index.css";
import DynamicAlert from "../../common/DynamicAlert";

const ShowCase = () => {
  const { id } = useParams();
  const [userCase, setUserCase] = useState();
  const [hearingModal, setHearingModal] = useState(false);
  const [hearing, setHearing] = useState();
  const [comments, setComments] = useState();
  const { register, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    getCaseDetail();
    getAllComments()
  }, []);

  const toggleHearing = () => {
    setHearingModal(!hearingModal);
  };

  const getCaseDetail = async () => {
    const res = await caseDetail(id);
    if (res) {
      if (res.status) {
        setUserCase(res.data);
      }
    }
  };

  const getAllComments = async () => {
    const res = await getComments(id);
    if (res) {
      if (res.status) {
        setComments(res.total_entries);
      }
    }
  };

  const handleComment = async (data) => {
    const res = await addComment(id, data);
    if (res) {
      if (res.status) {
        toast.success("Comment Added", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
                reset();
        getCaseDetail();
         getAllComments();
      }
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Your Cases"
        parent_url="/neutral/cases"
        title="Case Detail"
      />
      {userCase ? (
        <>
          <HearingModal
            open={hearingModal}
            toggle={toggleHearing}
            hearing={hearing}
          />
          <Container fluid>
            {/* <div className="d-flex justify-content-end mb-3">
          <Button color="primary" className="main-theme-btn" onClick={toggle}>
            Add Comment
          </Button>
        </div> */}
            {userCase && (
              <>
                <DynamicAlert userType="neutral" case_id={id} />
                <Row className="case_detail">
                  <Col lg={8} className="d-flex align-items-stretch">
                    <Card className="w-100">
                      <CardBody>
                        <div className="header pb-3 mb-3">
                          <div className="d-flex align-items-center mb-3">
                            <h5 className="f-w-600 mb-0">{userCase.subject}</h5>
                            <Tag className="ml-3" status={userCase.status} />
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6 className="text-black-50">
                              Case No: {userCase.case_no}
                            </h6>
                            <p className="text-black-50">
                              Created on{" "}
                              {moment(userCase.created_at).format("ll")}
                            </p>
                          </div>
                        </div>
                        <div className="mb-4">
                          <h6>Process</h6>
                          <p className="f-14 f-w-300 text-capitalize">
                            {userCase.process
                              ? userCase.process
                              : "Not assigned"}
                          </p>
                        </div>
                        <div className="mb-4">
                          <h6>Background</h6>
                          <p className="f-14 f-w-300">{userCase.background}</p>
                        </div>
                        <div>
                          <h6>Claims</h6>
                          <p className="f-14 f-w-300">{userCase.claims}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                      <CardBody className="height-equal log-content">
                        <h5 className="mb-3">Attachments</h5>
                        {userCase.attachments.length > 0 ? (
                          userCase.attachments.map((f) => (
                            <div className="logs-element">
                              <div className="circle-double-odd"></div>
                              <span>
                                <a
                                  target="_blank"
                                  href={`${config.BASE_URL}${f.path}`}
                                >
                                  {f.title}
                                </a>
                              </span>
                              <span className="pull-right">
                                {moment(f.created_at).format("DD/MM/YYYY")}
                              </span>
                            </div>
                          ))
                        ) : (
                          <p className="text-black-50">No files found</p>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <h5 className="mb-5">Respondants</h5>
                            <div className="activity mb-3">
                              {userCase.respondants.length > 0 ? (
                                userCase.respondants.map((resp) => (
                                  <div className="media">
                                    <div className="gradient-round m-r-30">
                                      <User />
                                    </div>
                                    <div className="media-body">
                                      <h6 className="mb-2">
                                        {resp.first_name}
                                      </h6>
                                      <p className="mb-1 text-black-50">
                                        <i className="fa fa-envelope mr-2"></i>
                                        {resp.email}
                                      </p>
                                      <p className="text-black-50">
                                        <i className="fa fa-mobile-phone mr-2"></i>
                                        {resp.phone}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p className="text-black-50">No respondants</p>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <h5 className="mb-5">Claimants</h5>
                            <div className="activity mb-3">
                              {userCase.claimants.length > 0 ? (
                                userCase.claimants.map((claim) => (
                                  <div className="media">
                                    <div className="gradient-round m-r-30">
                                      <User />
                                    </div>
                                    <div className="media-body">
                                      <h6 className="mb-2">
                                        {claim.first_name}
                                      </h6>
                                      <p className="mb-1 text-black-50">
                                        <i className="fa fa-envelope mr-2"></i>
                                        {claim.email}
                                      </p>
                                      <p className="text-black-50">
                                        <i className="fa fa-mobile-phone mr-2"></i>
                                        {claim.phone}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p className="text-black-50">No respondants</p>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <h5 className="mb-3">Neutrals</h5>
                            <div className="new-users">
                              {userCase.neutrals.length > 0 ? (
                                <>
                                  {userCase.neutrals.map((n) => (
                                    <div className="media d-flex align-items-start mb-3">
                                      {/* <img
                              className="rounded-circle img-40 image-radius m-r-15"
                              src={Avatar}
                              alt=""
                            /> */}
                                      <div className="media-body mt-0">
                                        <h6 className="mb-0 f-w-700">
                                          {displayName(
                                            n.first_name,
                                            n.last_name
                                          )}
                                        </h6>
                                        <p className="mb-1">{n.email}</p>
                                        <p className="mb-1">{n.phone}</p>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <p>No neutrals assigned</p>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} md={12}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="recent-notification">
                            <h5 className="mb-5">Hearings</h5>
                            {userCase.recent_hearings.length > 0 ? (
                              userCase.recent_hearings.map((h) => (
                                <>
                                  <div
                                    className="media hearing-item"
                                    onClick={() => {
                                      setHearing(h);
                                      setHearingModal(true);
                                    }}
                                  >
                                    <h6>
                                      {moment(h.date).format("DD-MM-YYYY")}
                                    </h6>
                                    <div
                                      className={`media-body ${getColor(
                                        h.status
                                      )}`}
                                    >
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span className="f-w-600">
                                          {h.agenda}
                                        </span>
                                        <Tag status={h.status} />
                                      </div>
                                      <p className="m-0">
                                        Meeting Time:{" "}
                                        {moment(h.date).format("hh:mm A")}
                                      </p>
                                      <p className="mb-2">
                                        Meeting Link:{" "}
                                        <a href={h.start_url} target="_blank">
                                          {subString(h.start_url, 40)}
                                        </a>
                                      </p>
                                      <p className="f-12">
                                        {subString(h.description, 100)}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              ))
                            ) : (
                              <p>No hearings yet</p>
                            )}
                            {userCase.recent_hearings.length === 5 && (
                              <Link
                                to={`/neutral/cases/${id}/hearings`}
                                className="float-right"
                              >
                                View More
                              </Link>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={12}>
                        <Card>
                          <CardBody id="comment">
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mb-3">Comments</h5>
                              <p className="text-black-50 ">
                                {comments} Comments
                              </p>
                            </div>
                            <Form
                              onSubmit={handleSubmit(handleComment)}
                              className="validation"
                            >
                              <FormGroup>
                                <Label hidden>Title</Label>
                                <input
                                  type="text"
                                  name="title"
                                  className="comment-text"
                                  placeholder="Add a title"
                                  ref={register({ required: true })}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label hidden>Content</Label>
                                <textarea
                                  className="comment-text mb-2"
                                  rows="3"
                                  name="content"
                                  placeholder="Add a comment"
                                  ref={register({ required: true })}
                                ></textarea>
                                <span>
                                  {errors.content && "Comment is empty!"}
                                </span>
                                <Button className="" type="submit">
                                  Send
                                </Button>
                              </FormGroup>
                            </Form>
                            {userCase.case_comments.length > 0 && (
                              <div className="comments-container">
                                <ul className="list">
                                  {userCase.case_comments.map((c) => {
                                    if (c.status !== "blocked") {
                                      return (
                                        <li className="list-item mb-4">
                                          <div className="media-body mt-0 d-flex justify-content-between">
                                            <h6 className="mb-0 f-w-700">
                                              {c.title}
                                              <span className="time">
                                                {moment(c.created_at).from(
                                                  moment()
                                                )}
                                              </span>
                                            </h6>
                                            <>
                                              <p>
                                                -
                                                {displayName(
                                                  c.created_by.first_name,
                                                  c.created_by.last_name
                                                )}
                                              </p>
                                            </>
                                          </div>
                                          <p className="mb-1">{c.content}</p>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                            )}
                            {userCase.case_comments.length === 5 && (
                              <Link
                                to={`/neutral/cases/${id}/comments`}
                                className="float-right"
                              >
                                View More
                              </Link>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export default ShowCase;
