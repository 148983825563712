import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import { user_authorizer } from "../utils/authorizer";

import UserDashboard from "../components/UserComponents/UserDashboard";
import AllCases from "../components/UserComponents/Case/AllCases";
import CreateCase from "../components/UserComponents/Case/CreateCase";
import ShowCase from "../components/UserComponents/Case/ShowCase";
import SecureYourContracts from "../components/UserComponents/SecureYourContracts";
import Billing from "../components/UserComponents/Billing";
import PaymentFail from "../pages/PaymentFail";
import PaymentSuccess from "../pages/PaymentSuccess";
import AllHearings from "../components/common/AllHearings";
import ContactCoordinator from "../components/common/ContactCoordinator";
import AllComments from "../components/UserComponents/Case/Comments";


const PrivateRoutes = ({ logout, currMenu }) => {
  const PrivateRoute = ({
    component: Component,
    role,
    menu,
    render,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (render) {
            return render;
          } else {
            return user_authorizer()
              ? (currMenu(menu), (<Component {...rest} />))
              : (logout(), (<Redirect to="/user/login" />));
          }
        }}
      />
    );
  };

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/user/dashboard"
        component={UserDashboard}
        menu={"user_dashboard"}
      />
      <PrivateRoute
        exact
        path="/user/contact_coordinator"
        render={<ContactCoordinator />}
      />
      <PrivateRoute
        exact
        path="/user/cases"
        component={AllCases}
        menu={"all_cases"}
      />
      <PrivateRoute
        exact
        path="/user/cases/create"
        component={CreateCase}
        menu={"create_case"}
      />
      <PrivateRoute
        exact
        path="/user/cases/:id/payment_fail"
        component={PaymentFail}
        menu={"payment"}
      />
      <PrivateRoute
        exact
        path="/user/cases/:id/payment_success"
        component={PaymentSuccess}
        menu={"payment"}
      />
      <PrivateRoute
        exact
        path="/user/cases/:id"
        component={ShowCase}
        menu={"case_detail"}
      />
      <PrivateRoute
        exact
        path="/user/cases/:id/comments"
        component={AllComments}
        menu={"case_detail"}
      />
      <PrivateRoute
        exact
        path="/user/secure_your_contracts"
        component={SecureYourContracts}
        menu={"secure_your_contracts"}
      />
      <PrivateRoute
        exact
        path="/user/cases/:id/payment"
        component={Billing}
        menu={"payment"}
      />
      <PrivateRoute
        exact
        path="/user/cases/:id/hearings"
        render={<AllHearings user />}
        menu={"Hearings"}
      />

      <Route render={() => <Redirect to="/user/dashboard" />} />
    </Switch>
  );
};

export default PrivateRoutes;
