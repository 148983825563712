import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import { disputes } from "../../constant/values";
import { hideEmail } from "../../utils/util";
import { user_signup, set_reset_user_password } from "../../services/user_api";
import Select from "react-multiselect-checkboxes";

const UserSignup = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [formData, setFormData] = useState({});
  const [otpForm, setOtpForm] = useState(false);
  const [welcomeScreen, setWelcomeScreen] = useState(false);
  const [phone, setPhone] = useState();
  const [pass, setPass] = useState();
  const [otherIssue, setOtherIssue] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [errorMsg, setErrorMsg] = useState();

  const sendOtp = async (data) => {
    if (selectedOption.length === 0) {
      setErrorMsg("Select atlease one option!");
      return;
    }
    setFormData(data);
    const caseType = selectedOption.map((selected) => selected.value);

    const payload = {
      salutation: data.salutation,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      case_type: data.case_type || caseType.join(", "),
    };
    const res = await user_signup(payload);
    if (res) {
      if (res.status) {
        setOtpForm(true);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  const submitOtp = async (data) => {
    const otp = data.otp_1 + data.otp_2 + data.otp_3 + data.otp_4;
    const payload = {
      user_name: formData.email,
      otp_code: otp,
      password: formData.password,
    };
    const res = await set_reset_user_password(payload);
    if (res) {
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setWelcomeScreen(true);
      }
    }
  };

  const WelcomeScreen = (
    <Col className="auth col-11 text-center">
      <div className="mb-4 ">
        <h3 className="font-weight-bold ">
          Thankyou for signing up in our platform
        </h3>
      </div>
      <p className="text">We will get in touch with you shortly</p>
      <p className="text">
        Meanwhile, Relax and know more{" "}
        <a href="https://medium.com/@thesettlementtable">about us!</a>
      </p>
    </Col>
  );

  const confirmPassword = (e) => {
    if (e === pass) return true;
    else return false;
  };

  const handleSelect = (data) => {
    setSelectedOption(data);
    if (data.length === 1) {
      if (data[0].label === "Other") {
        setOtherIssue(true);
      }
    } else {
      setOtherIssue(false);
    }
  };

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 4) {
        const nextSibling = document.querySelector(
          `input[name=otp_${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  return (
    <>
      {welcomeScreen ? (
        WelcomeScreen
      ) : (
        <>
          {!otpForm ? (
            <Col className="auth col-10">
              <div className="mb-4 ">
                <h3 className="font-weight-bold">User Signup</h3>
              </div>

              <Form
                id="signupForm"
                className="theme-form validation"
                onSubmit={handleSubmit(sendOtp)}
                noValidate
              >
                <Row form>
                  <Col xl={2}>
                    <FormGroup className="required">
                      <Label>Salutation</Label>
                      <select
                        name="salutation"
                        className="form-control digits"
                        ref={register({ required: true })}
                      >
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="M/s.">M/s.</option>
                      </select>
                      <span>
                        {errors.salutation && "Salutation is required"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl={5}>
                    <FormGroup className="required">
                      <Label>First Name</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="first_name"
                        ref={register({ required: true })}
                      />
                      <span>
                        {errors.first_name && "First name is required"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xl={5}>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        ref={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col xl={6}>
                    <FormGroup className="required">
                      <Label>Mobile Number (without country code)</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={(event) =>
                          setPhone(event.target.value.replace(/\D/, ""))
                        }
                        ref={register({
                          required: true,
                          maxLength: 10,
                          minLength: 10,
                        })}
                      />
                      {errors.phone && errors.phone.type === "required" && (
                        <span>Mobile number is required</span>
                      )}
                      {errors.phone && errors.phone.type === "maxLength" && (
                        <span>Mobile number should be 10 digits</span>
                      )}
                      {errors.phone && errors.phone.type === "minLength" && (
                        <span>Mobile number should be 10 digits</span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6}>
                    <FormGroup className="required">
                      <Label>E-mail</Label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        ref={register({
                          required: "Enter your e-mail",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid e-mail address",
                          },
                        })}
                      />
                      <span>{errors.email && errors.email.message}</span>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col xl={6}>
                    <FormGroup className="required">
                      <Label>Password</Label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        autoComplete="new-password"
                        onChange={(e) => setPass(e.target.value)}
                        ref={register({ required: true })}
                      />
                      <span>{errors.password && "Password is required"}</span>
                    </FormGroup>
                  </Col>
                  <Col xl={6}>
                    <FormGroup className="required">
                      <Label>Confirm Password</Label>
                      <input
                        className="form-control"
                        type="password"
                        name="confirm_pass"
                        ref={register({
                          required: true,
                          validate: { confirmPassword },
                        })}
                      />
                      {errors.confirm_pass &&
                        errors.confirm_pass.type === "required" && (
                          <span>Confirm Password is required</span>
                        )}
                      {errors.confirm_pass &&
                        errors.confirm_pass.type !== "required" && (
                          <span>Passwords do not match</span>
                        )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col className="col">
                    <FormGroup className="required dispute">
                      <Label>Nature of Dispute (Select one or more)</Label>{" "}
                      <i
                        className="fa fa-info-circle main-theme-link"
                        id="tool_tip"
                      ></i>
                      <UncontrolledTooltip placement="right" target="tool_tip">
                        Can select multiple options
                      </UncontrolledTooltip>
                      {/* <select
                        name="case_type"
                        className="form-control"
                        ref={register({ required: true })}
                        multiple
                        onChange={(e) =>
                          e.target.value === "other"
                            ? setOtherIssue(true)
                            : setOtherIssue(false)
                        }
                      >
                        {disputes.map((i, index) => (
                          <option value={i.value}>{i.label}</option>
                        ))}
                      </select> */}
                      <Select
                        defaultValue={selectedOption}
                        onChange={handleSelect}
                        options={disputes}
                        isMulti={true}
                      />
                      {errorMsg && selectedOption.length === 0 && (
                        <span>{errorMsg}</span>
                      )}
                    </FormGroup>
                    {otherIssue && (
                      <FormGroup className="required">
                        <Label>Other Issue</Label>
                        <input
                          name="case_type"
                          className="form-control"
                          ref={register({ required: true })}
                        />
                        <span>
                          {errors.case_type && "Select one Issue type"}
                        </span>
                      </FormGroup>
                    )}
                  </Col>
                </Row>

                <FormGroup>
                  <Button
                    className="main-theme-btn"
                    type="submit"
                    form="signupForm"
                    block
                  >
                    Proceed
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Link to="/user/login">Already have an account?</Link>
                </FormGroup>
              </Form>
            </Col>
          ) : (
            <Col lg={9} xl={8} className="auth col-11">
              <div className="mb-4 ">
                <h3 className="font-weight-bold mb-3">Validate OTP</h3>
                <p className="text">
                  Please enter the OTP (one time password) to verify your
                  account. A Code has been sent to {hideEmail(formData.email)}
                </p>
              </div>
              <Form
                id="otpForm"
                className="validation"
                onSubmit={handleSubmit(submitOtp)}
              >
                <FormGroup>
                  <Label hidden>OTP</Label>
                  <Row form className="mb-5">
                    <Col xs={3}>
                      <input
                        className="form-control "
                        maxLength="1"
                        type="text"
                        name="otp_1"
                        onChange={handleChange}
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col xs={3}>
                      <input
                        className="form-control"
                        maxLength="1"
                        type="text"
                        name="otp_2"
                        onChange={handleChange}
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col xs={3}>
                      <input
                        className="form-control"
                        maxLength="1"
                        type="text"
                        name="otp_3"
                        onChange={handleChange}
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col xs={3}>
                      <input
                        className="form-control"
                        maxLength="1"
                        type="text"
                        name="otp_4"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>

                  <span>
                    {errors.otp_1 &&
                      errors.otp_2 &&
                      errors.otp_3 &&
                      errors.otp_4 &&
                      "OTP is required!"}
                  </span>
                </FormGroup>

                <FormGroup>
                  <Button
                    block
                    className="main-theme-btn"
                    form="otpForm"
                    type="submit"
                    color="primary"
                  >
                    Signup
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default UserSignup;
