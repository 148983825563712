import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import UserAuthPage from "../pages/UserAuthPage";
import NeutralAuthPage from "../pages/NeutralAuthPage";
import UserLogin from "../components/Login/UserLogin";
import UserSignup from "../components/Login/UserSignup";
import NeutralLogin from "../components/Login/NeutralLogin";
import NeutralSignup from "../components/Login/NeutralSignup";
import UserForgetPass from "../components/Login/UserForgotPass";

const Routes = ({ login }) => {
  return (
    <Switch>
      <Route
        exact
        path="/user/login"
        render={() => (
          <UserAuthPage action="login">
            <UserLogin authenticate={login} />
          </UserAuthPage>
        )}
      />

      <Route
        exact
        path="/user/signup"
        render={() => (
          <UserAuthPage action="signup">
            <UserSignup authenticate={login} />
          </UserAuthPage>
        )}
      />

      <Route
        exact
        path="/neutral/login"
        render={() => (
          <NeutralAuthPage action="login">
            <NeutralLogin authenticate={login} />
          </NeutralAuthPage>
        )}
      />
      <Route
        exact
        path="/neutral/signup"
        render={() => (
          <NeutralAuthPage action="signup">
            <NeutralSignup authenticate={login} />
          </NeutralAuthPage>
        )}
      />
      <Route exact path="/user" render={() => <Redirect to="/user/login" />} />
      <Route
        exact
        path="/neutral"
        render={() => <Redirect to="/neutral/login" />}
      />
      <Route
        exact
        path="/user/forgot_password"
        render={() => (
          <UserAuthPage action="login">
            <UserForgetPass />
          </UserAuthPage>
        )}
      />
      <Route exact path="/" render={() => <Redirect to="/user/login" />} />
      <Route render={() => <Redirect to="/user/login" />} />
    </Switch>
  );
};

export default Routes;
