import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import {
  CardBody,
  Form,
  FormGroup,
  Label,
  Card,
  Row,
  Col,
  Button,
  InputGroup,
} from "reactstrap";
import { Upload } from "antd";
import "antd/lib/upload/style/index.css";
import "antd/lib/button/style/index.css";
import { InboxOutlined } from "@ant-design/icons";

import { hideEmail } from "../../utils/util";
import {
  neutral_signup,
  set_reset_neutral_password,
} from "../../services/neutral_api";

const NeutralSignup = () => {
  const { register, handleSubmit, errors, control, getValues } = useForm();
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "accreditation",
  });
  const [formData, setFormData] = useState({});
  const [otpForm, setOtpForm] = useState(false);
  const [welcomeScreen, setWelcomeScreen] = useState(false);
  const [phone, setPhone] = useState();
  const [pass, setPass] = useState();
  const [fileList, setFileList] = useState([]);
  const { Dragger } = Upload;

  useEffect(() => {
    prepend({}, false);
  }, []);

  const sendOtp = async (data) => {
    setFormData(data);
    const form = new FormData();
    form.append("salutation", data.salutation);
    form.append("first_name", data.first_name);
    form.append("last_name", data.last_name);
    form.append("email", data.email);
    form.append("phone", data.phone);

    data.accreditation.forEach((acc) => {
      for (let key in acc) {
        form.append(`accreditations[][${key}]`, acc[key]);
      }
    });
    fileList.forEach((file) => {
      form.append("files[]", file);
    });

    const res = await neutral_signup(form);
    if (res) {
      if (res.status) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setOtpForm(true);
      }
    }
  };

  const confirmPassword = (e) => {
    if (e === pass) return true;
    else return false;
  };

  const submitOtp = async (data) => {
    const otp = data.otp_1 + data.otp_2 + data.otp_3 + data.otp_4;
    const payload = {
      user_name: formData.email,
      otp_code: otp,
      password: formData.password,
    };
    const res = await set_reset_neutral_password(payload);
    if (res) {
      if (res.status) {
        toast.success(res?.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setWelcomeScreen(true);
      }
    }
  };

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 4) {
        const nextSibling = document.querySelector(
          `input[name=otp_${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  // const handleCheckbox = () => {
  //   setDisabled(!getValues("accept"));
  // };

  //File upload props
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const WelcomeScreen = (
    <Col className="auth col-11 text-center">
      <div className="mb-4 ">
        <h3 className="font-weight-bold ">
          Thankyou for signing up in our platform
        </h3>
      </div>
      <p className="text">We will get in touch with you shortly</p>
      <p className="text">
        Meanwhile, Relax and know more{" "}
        <a href="https://medium.com/@thesettlementtable">about us!</a>
      </p>
    </Col>
  );

  return (
    <>
      {welcomeScreen ? (
        WelcomeScreen
      ) : (
        <>
          {!otpForm ? (
            <>
              <Col className="auth col-10">
                <div className="mb-4 ">
                  <h3 className="font-weight-bold">Neutral Signup</h3>
                </div>
                <Form
                  id="signupForm"
                  className="theme-form validation"
                  onSubmit={handleSubmit(sendOtp)}
                  noValidate
                >
                  <Row form>
                    <Col xl={2} md={12}>
                      <FormGroup className="required">
                        <Label>Salutation</Label>
                        <select
                          name="salutation"
                          className="form-control digits"
                          ref={register({ required: true })}
                        >
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                        </select>
                        <span>
                          {errors.salutation && "Salutation is required"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col lg={5} md={12}>
                      <FormGroup className="required">
                        <Label>First Name</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          ref={register({ required: true })}
                        />
                        <span>
                          {errors.first_name && "First name is required"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col lg={5} md={12}>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          ref={register}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form className="d-flex justify-content-around">
                    <Col lg={6} md={12}>
                      <FormGroup className="required">
                        <Label>Mobile Number (without country code)</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={(event) =>
                            setPhone(event.target.value.replace(/\D/, ""))
                          }
                          ref={register({
                            required: true,
                            maxLength: 10,
                            minLength: 10,
                          })}
                        />
                        {errors.phone && errors.phone.type === "required" && (
                          <span>Mobile number is required</span>
                        )}
                        {errors.phone && errors.phone.type === "maxLength" && (
                          <span>Mobile number should be 10 digits</span>
                        )}
                        {errors.phone && errors.phone.type === "minLength" && (
                          <span>Mobile number should be 10 digits</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12}>
                      <FormGroup className="required">
                        <Label>E-mail</Label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          autoComplete="email"
                          ref={register({
                            required: "Enter your e-mail",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Enter a valid e-mail address",
                            },
                          })}
                        />
                        <span>{errors.email && errors.email.message}</span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form className="d-flex justify-content-around">
                    <Col lg={6} md={12}>
                      <FormGroup className="required">
                        <Label>Password</Label>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          autoComplete="new-password"
                          onChange={(e) => setPass(e.target.value)}
                          ref={register({ required: true })}
                        />
                        <span>{errors.password && "Password is required"}</span>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12}>
                      <FormGroup className="required">
                        <Label>Confirm Password</Label>
                        <input
                          className="form-control"
                          type="password"
                          name="confirm_pass"
                          ref={register({
                            required: true,
                            validate: { confirmPassword },
                          })}
                        />
                        {errors.confirm_pass &&
                          errors.confirm_pass.type === "required" && (
                            <span>Confirm Password is required</span>
                          )}
                        {errors.confirm_pass &&
                          errors.confirm_pass.type !== "required" && (
                            <span>Passwords do not match</span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-around">
                    <Col sm={12}>
                      <FormGroup className="required">
                        <Label>Designation</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="designation"
                          ref={register({ required: true })}
                        />
                        <span>
                          {errors.designation && "Designation is required"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col sm={12}>
                      <FormGroup>
                        <Label>Accreditation</Label>
                        {fields.map(({ id }, index) => (
                          <Row form className="mb-1" key={id}>
                            <InputGroup>
                              <input
                                className="form-control"
                                type="text"
                                name={`accreditation[${index}].name`}
                                placeholder="Name"
                                ref={register()}
                              />
                              <input
                                className="form-control"
                                type="text"
                                name={`accreditation[${index}].institution`}
                                placeholder="Institution"
                                ref={register()}
                              />
                              {fields.length > 1 && (
                                <span
                                  className="px-3 d-flex align-items-center "
                                  onClick={() => remove(index)}
                                >
                                  <i
                                    className="fa fa-minus-square-o icon-red"
                                    style={{ fontSize: "1.5rem" }}
                                  ></i>
                                </span>
                              )}
                            </InputGroup>
                          </Row>
                        ))}
                        <div className="">
                          <p
                            style={{ fontSize: "1rem" }}
                            className="btn btn-link px-0 float-right link"
                            onClick={() => append({})}
                          >
                            Add more<i className="fa fa-plus ml-2"></i>
                          </p>
                        </div>
                        <span>
                          {errors.accreditation && "Accreditation is required"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col sm={12}>
                      <FormGroup>
                        <Label>Upload Documents</Label>
                        <div>
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col sm={12}>
                      <FormGroup>
                        <div className="ml-3">
                          <div className="checkbox checkbox-primary">
                            <input
                              id="1"
                              type="checkbox"
                              name="accept"
                              ref={register()}
                            />
                            <label htmlFor="1">
                              Are you willing to undertake Pro-Bono Matters, if
                              any subject to prior notice and convenience?
                            </label>
                            <span>
                              {errors.accept && "Please accept to continue"}
                            </span>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Button
                      className="main-theme-btn"
                      block
                      type="submit"
                      form="signupForm"
                    >
                      Proceed
                    </Button>
                  </FormGroup>

                  <Row form className="d-flex flex-column align-items-center ">
                    <Col sm={12}>
                      <FormGroup>
                        <Link to="/neutral/login">
                          Already have an account?
                        </Link>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          ) : (
            <Col lg={9} xl={8} className="auth col-11">
              <div className="mb-4 ">
                <h3 className="font-weight-bold mb-3">Validate OTP</h3>
                <p className="text">
                  Please enter the OTP (one time password) to verify your
                  account. A Code has been sent to {hideEmail(formData.email)}
                </p>
              </div>
              <Form
                id="otpForm"
                className="validation"
                onSubmit={handleSubmit(submitOtp)}
              >
                <FormGroup>
                  <Label hidden>OTP</Label>
                  <Row form className="mb-5">
                    <Col xs={3}>
                      <input
                        className="form-control "
                        maxLength="1"
                        type="text"
                        name="otp_1"
                        onChange={handleChange}
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col xs={3}>
                      <input
                        className="form-control"
                        maxLength="1"
                        type="text"
                        name="otp_2"
                        onChange={handleChange}
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col xs={3}>
                      <input
                        className="form-control"
                        maxLength="1"
                        type="text"
                        name="otp_3"
                        onChange={handleChange}
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col xs={3}>
                      <input
                        className="form-control"
                        maxLength="1"
                        type="text"
                        name="otp_4"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>

                  <span>
                    {errors.otp_1 &&
                      errors.otp_2 &&
                      errors.otp_3 &&
                      errors.otp_4 &&
                      "OTP is required!"}
                  </span>
                </FormGroup>

                <FormGroup>
                  <Button
                    block
                    form="otpForm"
                    className="main-theme-btn"
                    type="submit"
                    color="primary"
                  >
                    Signup
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default NeutralSignup;
