 export class ConfigDB {
  static data = {
   settings: {
       layout_type: 'ltr',
   sidebar: {
       type:  'default',
       body_type:  'default' ,
   },
       sidebar_setting:  'default-sidebar' ,
       sidebar_backround:  'light-sidebar' ,
              },
       color: {
       layout_version:  'light' ,
       color: 'color-2' ,
       primary_color:  '#0288d1' ,
       secondary_color:  '#26c6da' ,
       mix_layout:  'light-only' ,
           },
       router_animation: 'fadeIn'
   }
   }

export default ConfigDB;