import React, { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';
import { BrowserRouter, useHistory } from "react-router-dom";
import { Provider } from 'react-redux';
import store from '../store/index';

import { neutral_authorizer, user_authorizer } from "../utils/authorizer";

import { ScrollContext } from 'react-router-scroll-4';
import UserPrivateRoutes from "../routes/UserPrivateRoutes";
import NeutralPrivateRoutes from "../routes/NeutralPrivateRoutes";
import Layout from "../pages/Layout"
import "antd/lib/alert/style/index.css"


import Routes from "../routes/Routes";




const AppLayout = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [currMenu, setCurrMenu] = useState("dashboard");


  useEffect(() => {
    if (user_authorizer()) {
      setAuthenticated(true);
    }
    if (neutral_authorizer()) {
      setAuthenticated(true);
    }
  }, []);

  const login = () => {
    setAuthenticated(true);
  };

  const logout = () => {
    setAuthenticated(false);
  };

   

  return (
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <ScrollContext>
          <div>
            <Loader />
            <div className="page-wrapper">
              <Layout
                auth={authenticated}
                logout={logout}
                currMenu={currMenu}
              >
                {user_authorizer() && (
                  <UserPrivateRoutes logout={logout} currMenu={setCurrMenu} />
                )}
                {neutral_authorizer() && (
                  <NeutralPrivateRoutes
                    logout={logout}
                    currMenu={setCurrMenu}
                  />
                )}
                {!user_authorizer() && !neutral_authorizer() && (
                  <Routes login={login} />
                )}
              </Layout>
            </div>
            <ToastContainer />
          </div>
        </ScrollContext>
      </BrowserRouter>
    </Provider>
  );
}

export default AppLayout;