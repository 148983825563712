import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Container, Row, Col, CardHeader } from "reactstrap";
import moment from "moment";
import { Skeleton } from "antd";
import "antd/lib/skeleton/style/index.css";

import { all_cases } from "../../../services/user_api";
import { toast } from "react-toastify";
import { subString, getColor } from "../../../utils/util";

const AllCases = () => {
  const history = useHistory();
  const [cases, setCases] = useState();

  useEffect(() => {
    all_cases().then((res) => {
      if (res) {
        if (res.status) {
          setCases(res.data);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }
    });
  }, []);

  return (
    <>
      <Breadcrumb title="Cases" />
      <div>
        <Container fluid>
          {cases ? (
            <>
              {cases?.length === 0 ? (
                <Card>
                  <CardBody>
                    <Row className="d-flex justify-content-center">
                      <div className="text-center">
                        <h4>You have no cases</h4>
                        <Link
                          className="btn btn-primary btn-sm"
                          to="/user/cases/create"
                        >
                          <i className="fa fa-plus mr-2"></i>
                          Create Case
                        </Link>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              ) : (
                <>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody className="p-3">
                          <div className="d-flex justify-content-end">
                            <Link
                              className="btn btn-primary btn-sm"
                              to="/user/cases/create"
                            >
                              <i className="fa fa-plus mr-2"></i>
                              Create Case
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    {cases?.map((item, i) => (
                      <Col
                        lg={4}
                        md={6}
                        sm={12}
                        className="d-flex align-items-stretch"
                        key={i}
                      >
                        <Card
                          className="w-100 ribbon-wrapper card-container pointer"
                          onClick={() => history.push(`/user/cases/${item.id}`)}
                        >
                          <CardBody className="p-4">
                            <div className="card-overlay"></div>
                            <div
                              className={`ribbon ribbon-${getColor(
                                item.status
                              )} ribbon-right text-capitalize`}
                            >
                              {item.status.replace("_", " ")}
                            </div>
                            <div className="d-flex flex-column ">
                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between">
                                  <h5 className="f-w-600">{item.subject}</h5>
                                  <h6>{item.case_no}</h6>
                                </div>

                                <p
                                  style={{ color: "rgba(0,0,0,0.7)" }}
                                  className="mb-3"
                                >
                                  {subString(item.background, 100)}
                                </p>
                                <div>
                                  <p className="text-black-50 mb-0">
                                    <i className="fa fa-file-text-o"></i>{" "}
                                    {subString(item.claims, 30)}
                                  </p>
                                </div>
                                <div className="f-w-500 text-black-50">
                                  <i className="fa fa-paperclip"></i>
                                  {` ${item.attachments.length} Files`}
                                </div>
                                <p className="text-black-50">
                                  <i className="fa fa-calendar"></i>{" "}
                                  {moment(item.created_at).format("ll")}
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default AllCases;
