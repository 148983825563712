import React from "react";
import { CardBody, Container, Card } from "reactstrap";

const ContactCoordinator = () => {
  return (
    <div className="pt-2">
      <Container fluid className="mt-3">
        <Card>
          <CardBody>
            <h5 className="mb-3">Contact coordinator</h5>
            <p>Phone: +91 9489244442</p>
            <p>Email: info@thesettlementtable.in</p>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ContactCoordinator;
