import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Button,
} from "reactstrap";
import { Link, useParams, useLocation } from "react-router-dom";

const PaymentFail = (props) => {
  const { id } = useParams();
  const location = useLocation();
  return (
    <>
      <Row className="p_container">
        <Col>
          <Card className=" mt-3 h-100">
            <CardBody className="d-flex justify-content-center align-items-center">
              <div className="text-center ">
                <i className="fa fa-times-circle p_icon_fail mb-3"></i>
                <h2 className="display-5 mb-3">Payment Failed!</h2>
                <div className="p_content d-flex flex-column align-items-center mb-5">
                  <p className="f-25">
                    An error occured while processing your payment
                    {props.location.state.payment_id}.
                    <br />
                    For further details check out our{" "}
                    <a
                      target="_blank"
                      href="https://www.thesettlementtable.in/pricing_refund_policy"
                    >
                      Refund Policy
                    </a>
                  </p>
                  <Link to={`/user/cases/${id}/payment`}>
                    <button className="btn p_button mb-3">Try Again</button>
                  </Link>
                  <Link to={`/user/cases/${id}`}>
                    <i className="fa fa-caret-right"></i> Back to "Your Case"
                  </Link>
                </div>
                <p>
                  In case of any grievances/complaints/ queries, please feel
                  free to write to us at{" "}
                  <a href="mailto:info@thesettlementtable.in">
                    info@thesettlementtable.in
                  </a>{" "}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PaymentFail;
