import React, { useState, Fragment, useEffect } from "react";
import Language from "./language";
import UserMenu from "./userMenu";
// import Notification from './notification';
// import SearchHeader from './searchHeader';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AlignLeft, Maximize, MoreHorizontal, Bell } from "react-feather";
import moment, { now } from "moment";
import Notification from "./notification";
import { getCalendarEvents as userEvents } from "../../../services/user_api";
import { getCalendarEvents as neutralEvents } from "../../../services/neutral_api";

import logo from "../../../assets/images/logo.svg";

import RasieQuery from "../RaiseQuery";
import { FormProvider } from "react-hook-form";

// import Help from "../Help";

const Header = ({ logout, currMenu }) => {
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);
  const [help, setHelp] = useState(false);
  const [query, setQuery] = useState(false);
  const [headerbar, setHeaderbar] = useState(true);
  const [notification, setNotificaion] = useState([]);

  useEffect(() => {
    const getNotification = async () => {
      let res;
      const todayEvents = [];
      const today = moment();
      const user = localStorage.getItem("user");
      const neutral = localStorage.getItem("neutral");
      if (user) {
        res = await userEvents(today.month() + 1, today.year());
      }
      if (neutral) {
        res = await neutralEvents(today.month() + 1, today.year());
      }
      if (res) {
        if (res.status) {
          const events = res.data;
          for (let key in events) {
            if (today.isSame(key, "day")) {
              todayEvents.push(...events[key]);
            }
          }
          setNotificaion(todayEvents);
        }
      }
    };
    getNotification();
  }, []);

  const toggleQuery = () => {
    setQuery(!query);
  };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  };

  const toggleHelp = () => {
    setHelp(!help);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  // const handleMode = (e) => {
  //   const mode = e.target.value

  //   // localStorage.setItem('layout_version', mode)
  //   if (isDarkMode && mode === 'dark-only') {
  //     setIsDarkMode(false);
  //     document.body.className = 'light-only';
  //   }
  //   else if (isDarkMode === false && mode === 'dark-only') {
  //     setIsDarkMode(true);
  //     document.body.className = mode
  //   }
  //   dispatch({
  //     type: "ADD_MIXlAYOUT",
  //     payload: mode,
  //   });
  // }

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/default">
                <img className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <Link onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </Link>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? "" : "open"}`}>
              <li>{/* <SearchHeader /> */}</li>
              {/* <li>
                <div class="toggleWrapper">
                  <input type="checkbox" class="dn" id="dn" value='dark-only' checked={isDarkMode} onChange={handleMode}/>
                  <label for="dn" class="toggle">
                    <span class="toggle__handler">
                      <span class="crater crater--1"></span>
                      <span class="crater crater--2"></span>
                      <span class="crater crater--3"></span>
                    </span>
                    <span class="star star--1"></span>
                    <span class="star star--2"></span>
                    <span class="star star--3"></span>
                    <span class="star star--4"></span>
                    <span class="star star--5"></span>
                    <span class="star star--6"></span>
                  </label>
                </div>
              </li> */}

              <li>
                <a className="" href="#!" onClick={toggleQuery}>
                  {/*  <i className="fa fa-question-circle f-20"></i>  */}Raise
                  Query
                </a>
              </li>
              <li className="onhover-dropdown">
                <Notification notification={notification} />
                <Bell />
                <span className={notification?.length > 0 && "dot"}></span>
                <Notification />
              </li>
              {/* <li>
                <a className="f-20" href="#!" onClick={toggleHelp}>
                  <i className="fa fa-question-circle"></i>
                </a>
              </li> */}
              <li>
                <span style={{ textTransform: "capitalize" }}>
                  Hi{" "}
                  {localStorage.getItem("user_name")
                    ? localStorage.getItem("user_name")
                    : "there"}
                </span>
              </li>
              {/* <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li> */}
              <li className="pr-0">
                <UserMenu logout={logout} />
              </li>
              <li></li>
              {/* <li className="onhover-dropdown">
                <a className="txt-dark" href="#javascript">
                  <h6>EN</h6>
                </a>
                <Language />
              </li> */}
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal />
            </div>
          </div>
        </div>
        {/* <Help isOpen={help} toggle={toggleHelp} currMenu={currMenu} /> */}
      </div>
      <RasieQuery modal={query} toggle={toggleQuery} />
    </Fragment>
  );
};
export default Header;
