import React from "react";

const displayTag = (status) => {
  switch (status) {
    case "active":
      return <span className="badge badge-success">Active</span>;
    case "inactive":
      return <span className="badge badge-danger">In-Active</span>;
    case "submitted":
      return <span className="badge badge-success">Submitted</span>;
    case "scheduled":
      return <span className="badge badge-info">Scheduled</span>;
    case "cancelled":
      return <span className="badge badge-danger">Cancelled</span>;
    case "completed":
      return <span className="badge badge-success">Completed</span>;
    case "rescheduled":
      return <span className="badge badge-warning">Re-Scheduled</span>;
    case "expired":
      return <span className="badge badge-danger">Expired</span>;
    case "in_review":
      return <span className="badge badge-info">In Review</span>;
    case "accepted":
      return <span className="badge badge-secondary">Accepted</span>;
    case "paid":
      return <span className="badge badge-secondary">Paid</span>;
    case "pending_payment":
      return <span className="badge badge-warning">Payment Pending</span>;
    case "on_hold":
      return <span className="badge badge-warning">On Hold</span>;
    case "rejected":
      return <span className="badge badge-danger">Rejected</span>;
    case "closed":
      return <span className="badge badge-danger">Closed</span>;
    case "created":
      return <span className="badge badge-info">Scheduled</span>;
    case "success":
      return <span className="badge badge-success">Success</span>;
    case "created-payment":
      return <span className="badge badge-warning">Created</span>;
    case "failed":
      return <span className="badge badge-danger">Failed</span>;
    case "approved":
      return <span className="badge badge-success">Approved</span>;
    case "partially_paid":
      return <span className="badge badge-warning">Partially Paid</span>;
    default:
      return;
  }
};

const Tag = ({ status, className }) => {
  return (
    <>
      <span className={className}>{displayTag(status)}</span>
    </>
  );
};

export default Tag;
