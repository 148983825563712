import { Briefcase, Home, Shield, Bookmark, Users, User } from "react-feather";

export const USER_MENUITEMS = [
  {
    path: "/user/dashboard",
    title: "Home",
    icon: Home,
    type: "link",
    active: true,
  },
  {
    path: "/user/cases",
    title: "Cases",
    icon: Briefcase,
    type: "link",
    active: false,
  },
  {
    path: "/user/secure_your_contracts",
    title: "Secure your contracts",
    icon: Shield,
    type: "link",
    active: false,
  },
  {
    path: "/user/contact_coordinator",
    title: "Contact Coordinator",
    icon: User,
    type: "link",
    active: false,
  },
];

export const NEUTRAL_MENUITEMS = [
  {
    path: "/neutral/dashboard",
    title: "Home",
    icon: Home,
    type: "link",
    active: true,
  },
  {
    path: "/neutral/cases",
    title: "Your Cases",
    icon: Briefcase,
    type: "link",
    active: false,
  },
  // {
  //   path: "/neutral/remarks",
  //   title: "Remarks",
  //   icon: Bookmark,
  //   type: "link",
  //   active: false,
  // },
  {
    path: "/neutral/contact_coordinator",
    title: "Contact Coordinator",
    icon: User,
    type: "link",
    active: false,
  },
];


