import React, { useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { contract_method } from "../../constant/values";
import { content } from "../../constant/syc_content";

import { userQuery } from "../../services/user_api";



const SecureYourContracts = () => {
    const { register, handleSubmit, errors, setValue } = useForm();
  const [syc, setSyc] = useState({
    mediation: content.mediation,
    conciliation: content.conciliation,
    arbitration: content.arbitration,
  });

  const changeSyc = (e) => {
    setSyc({ ...syc, [e.target.name]: e.target.value });
  };

  

  const onSubmit = async (data) => {

      const person_id = localStorage.getItem("id");
    const user = localStorage.getItem("user");
   const category = "secure_contact";
    let payload;
    payload = {
      person_id,
      user,
      category,
      message: `SECURE YOUR CONTRACTS\n
        Type of contract : ${data.contract_type}\n
        Prefered method : ${data.method}`,
    };
   const res = await userQuery(payload);

    if (res) {
      if (res.status) {
       SweetAlert.fire({
         title: "Thank you!",
         text: "Our consultant will get in touch with you soon. ",
         type: "success",
       });
      }
    }

    
    
  };

  const handleQuery = async (data) => {
  
  };

  return (
    <div>
      <Breadcrumb title="Secure your contracts" />
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className="font-weight-bold text-uppercase mb-3">
              Secure your contracts
            </h4>
            <Form id="secure_your_contracts">
              <FormGroup>
                <Label className="f-w-600">Mediation</Label>
                <CopyToClipboard
                  text={syc.mediation}
                  className="main-theme-link ml-3"
                >
                  <span
                    onClick={() =>
                      toast.success("Text copied to Clipboard!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                      })
                    }
                  >
                    Copy text <i className="fa fa-clipboard"></i>
                  </span>
                </CopyToClipboard>
                <textarea
                  onChange={changeSyc}
                  className="form-control"
                  value={syc.mediation}
                  name="mediation"
                  rows="5"
                />
              </FormGroup>
              <FormGroup>
                <Label className="f-w-600">Conciliation</Label>
                <CopyToClipboard
                  text={syc.conciliation}
                  className="main-theme-link ml-3"
                >
                  <span
                    onClick={() =>
                      toast.success("Text copied to Clipboard!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                      })
                    }
                  >
                    Copy text <i className="fa fa-clipboard"></i>
                  </span>
                </CopyToClipboard>
                <textarea
                  onChange={changeSyc}
                  className="form-control"
                  value={syc.conciliation}
                  rows="5"
                  name="conciliation"
                />
              </FormGroup>
              <FormGroup>
                <Label className="f-w-600">Arbitration</Label>
                <CopyToClipboard
                  text={syc.arbitration}
                  className="main-theme-link ml-3"
                >
                  <span
                    onClick={() =>
                      toast.success("Text copied to Clipboard!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                      })
                    }
                  >
                    Copy text <i className="fa fa-clipboard"></i>
                  </span>
                </CopyToClipboard>
                <textarea
                  onChange={changeSyc}
                  className="form-control"
                  value={syc.arbitration}
                  name="arbitration"
                  rows="5"
                />
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="font-weight-bold text-uppercase mb-3">
              Customize the clauses for you contracts
            </h4>
            <Form
              id="cutomize_contract_clause"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row form>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <Label>What is the type of the contract?</Label>
                    <input
                      name="contract_type"
                      className="form-control"
                      ref={register}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Which method do you prefer?{" "}
                      <i
                        className="fa fa-info-circle main-theme-link"
                        id="tool_tip"
                      ></i>
                      <UncontrolledTooltip placement="right" target="tool_tip">
                        {
                          "Hybrid Clauses - Clauses giving two or more of the dispute resolution methods to resolve disputes"
                        }
                      </UncontrolledTooltip>
                    </Label>
                    <select
                      className="form-control"
                      name="method"
                      ref={register}
                    >
                      {contract_method.map((o) => (
                        <option value={o.value}>{o.label}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Button type="submit" className="main-theme-btn">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default SecureYourContracts;
