export const content = {
  conciliation: `In case of any disputes arising out of or in relation to this Agreement, the Parties agree to refer the disputes to ‘The Settlement Table’ and resolve the disputes by online Conciliation in accordance with the Rules and Guidelines of ‘The Settlement Table’.

The Number of Conciliator(s) shall be ….. The Conciliator(s) shall be appointed by ‘The Settlement Table’, in accordance with the Rules and Guidelines of ‘The Settlement Table’.

The language of the Conciliation shall be decided by the Conciliator(s), in consultation with the Parties, with due consideration as to the convenience and understanding of the Parties.  
`,

  mediation: `In case of any disputes arising out of or in relation to this Agreement, the Parties agree to refer the disputes to ‘The Settlement Table’ and resolve the disputes by online Mediation, in accordance with the Rules and Guidelines of ‘The Settlement Table’.

The Number of Mediator(s) shall be ….. The Mediator(s) shall be appointed by ‘The Settlement Table’, in accordance with the Rules and Guidelines of ‘The Settlement Table’.

The language of the Mediation shall be decided by the Mediator(s), in consultation with the Parties, with due consideration as to the convenience and understanding of the Parties. 
`,
  arbitration: `In case of any disputes between the Parties in relation to or arising out of this Agreement, including any question regarding its existence, validity or termination, or/and the claims and the matters concerning ……….., the Parties agree to refer the disputes to ‘The Settlement Table’ and resolve the disputes by online Arbitration, in accordance with the Rules and Guidelines of ‘The Settlement Table’.

The number of Arbitrator(s) shall be …… The appointment of the Arbitrator(s) shall be made by ‘The Settlement Table’, in accordance with the Rules and Guidelines of ‘The Settlement Table’. The decision of the Arbitrator(s) shall be final and binding on the parties. 

The seat of arbitration shall be ….. and the Governing Law shall be ……… 

The language of the online Arbitration shall be decided by the Arbitrator(s), in consultation with the Parties, with due consideration as to the convenience and understanding of the Parties. 
`,
};
