import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumb from "../common/breadcrumb";
import Calendar from "./UserCalendar";
import RaiseQuery from "../common/RaiseQuery";

const UserDashboard = () => {
  const [query, setQuery] = useState(false);
  const [calEvents, setCalEvents] = useState([]);

  // useEffect(() => {
  //   getCalendarEvents().then(res => {
  //     if (res) {
  //       if (res.status) {
  //        setCalEvents()
  //      }
  //    }
  //  })
  // }, [])

  const toggleQuery = () => {
    setQuery(!query);
  };

  return (
    <>
      <Breadcrumb title="Dashboard" />
      <Container fluid>
        <Row>
          <Col lg={7} md={12}>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Card>
                  <CardBody>
                    <Link to="/user/cases/create">
                      <Button className="main-theme-btn" block>
                        Register your case
                      </Button>
                    </Link>
                    <div className="mt-3">
                      <p className="f-16">
                        By clicking this, you can file the ongoing/existing
                        dispute.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Card>
                  <CardBody>
                    <Link to="/user/secure_your_contracts">
                      <Button className="main-theme-btn" block>
                        Secure you contracts
                      </Button>
                    </Link>
                    <div className="mt-3">
                      <p className="f-16">
                        By clicking this, you can have the options to refer
                        future disputes
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody className="d-flex flex-column align-items-start">
                    <Link to="/user/cases" className="mb-3 main-theme-link">
                      Already registered? View your case details
                    </Link>
                    <a onClick={toggleQuery} className="main-theme-link">
                      Get help in filing
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={5} sm={12}>
            <Card>
              <CardBody>
                <Calendar />
              </CardBody>
            </Card>
          </Col>
          {/* <Col md={12}> */}
          {/* <Row> */}

          {/* <Col sm={12}>
                  <Card>
                    <CardBody>
                      <Form>
                        <FormGroup>
                          <h5 className="f-w-400">
                            Subscribe to our latest newsletter
                          </h5>
                          <Row form>
                            <Col sm={8}>
                              <Input type="email" />
                            </Col>
                            <Col>
                              <Button block className="main-theme-btn">
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </Col> */}
          {/* </Row> */}
          {/* </Col> */}
        </Row>
        <RaiseQuery modal={query} toggle={toggleQuery} />
      </Container>
    </>
  );
};;

export default UserDashboard;
