import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Form,
  InputGroup,
  InputGroupAddon,
  Button,
  Row,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { Upload, Alert, Switch } from "antd";
import "antd/lib/upload/style/index.css";
import "antd/lib/alert/style/index.css";
import { InboxOutlined } from "@ant-design/icons";
import Breadcrumb from "../../common/breadcrumb";
import { useFieldArray, useForm } from "react-hook-form";
import { disputes } from "../../../constant/values";
import { all_cases, create_case } from "../../../services/user_api";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import SweetAlert from "sweetalert2";

const { Dragger } = Upload;

const CreateCase = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, control } = useForm();
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "respondants",
  });
  const {
    fields: claimFields,
    append: claimAppend,
    remove: claimRemove,
    prepend: claimPrepend,
  } = useFieldArray({
    control,
    name: "claimants",
  });
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploadig] = useState(false);
  const [phone1, setPhone1] = useState();
  const [phone2, setPhone2] = useState();
  const [otherIssue, setOtherIssue] = useState(false);
  const [existingCase, setExistingCase] = useState(false);
  const [existingCaseFiles, setExistingCaseFiles] = useState([]);

  useEffect(() => {
    prepend({}, false);
    claimPrepend({}, false);
  }, []);

  //File upload props
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (_, list) => {
      setFileList(list);
      return false;
    },
    multiple: true,
    fileList,
  };

  const existigCaseUploadProps = {
    onRemove: (file) => {
      const index = existingCaseFiles.indexOf(file);
      const newFileList = existingCaseFiles.slice();
      newFileList.splice(index, 1);
      setExistingCaseFiles(newFileList);
    },
    beforeUpload: (_, list) => {
      setExistingCaseFiles(list);
      return false;
    },
    multiple: true,
    fileList: existingCaseFiles,
  };

  const createCase = async (data) => {
    let form = new FormData();
    form.append("case_files[subject]", data.subject);
    form.append("case_files[party_name]", data.party_name);
    form.append("case_files[background]", data.background);
    form.append("case_files[claims]", data.claims);
    form.append("case_files[issue_type]", data.issue_type);
    data.respondants.forEach((resp) => {
      for (let key in resp) {
        if (resp[key]) {
          form.append(`case_files[respondants][][${key}]`, resp[key]);
        }
      }
    });
    data.claimants.forEach((resp) => {
      for (let key in resp) {
        if (resp[key]) {
          form.append(`case_files[claimants][][${key}]`, resp[key]);
        }
      }
    });
    fileList.forEach((file) => {
      form.append("case_files[files][]", file);
    });

    // Existing case details
    form.append("case_files[case_exist]", existingCase ? true : false);
    if (existingCase)
      form.append("case_files[case_exist_info]", data.case_exist_info);
    existingCaseFiles.forEach((file) => {
      form.append("case_files[exist_files][]", file);
    });

    const res = await create_case(form);
    if (res) {
      if (res.status) {
        toast.success("Case created successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        SweetAlert.fire({
          text: "Our consultant will get in touch and guide you futher! 'Have a great day' ",
          type: "success",
        }).then(() => {
          history.push("/user/cases");
        });
      }
    }
  };

  return (
    <>
      <Breadcrumb parent="Cases" parent_url="/user/cases" title="Create Case" />
      <Container fluid>
        <Card className="p-lg-4 p-md-2">
          <CardBody>
            <Form className="validation" onSubmit={handleSubmit(createCase)}>
              <Row>
                <Col lg={7} md={12}>
                  <FormGroup className="required">
                    <Label>Name of the party (Individual or Body)</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <select className="form-control digits">
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="M/s">M/s</option>
                          <option value=""></option>
                        </select>
                      </InputGroupAddon>

                      <input
                        className="form-control"
                        type="text"
                        name="party_name"
                        ref={register({ required: true })}
                      />
                    </InputGroup>
                    <span>{errors.party_name && "Party Name is required"}</span>
                  </FormGroup>
                  <FormGroup className="required">
                    <Label>Subject</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="subject"
                      ref={register({ required: true })}
                    />
                    <span>{errors.subject && "Subject is required"}</span>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Name of the opposite parties (Against whom you have your
                      claims)
                    </Label>
                    <Row form>
                      {fields.map(({ id }, index) => (
                        <Col sm={12} className="mb-3">
                          <InputGroup>
                            <input
                              className="form-control"
                              type="text"
                              name={`respondants[${index}].first_name`}
                              placeholder="Name"
                              ref={register()}
                            />
                            <input
                              className="form-control"
                              type="email"
                              name={`respondants[${index}].email`}
                              placeholder="E-mail"
                              ref={register({
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: "Enter a valid E-mail address",
                                },
                              })}
                            />
                            <input
                              className="form-control"
                              type="text"
                              name={`respondants[${index}].phone`}
                              placeholder="Mobile Number"
                              value={phone1}
                              onChange={(event) =>
                                setPhone1(event.target.value.replace(/\D/, ""))
                              }
                              ref={register()}
                            />

                            {fields.length > 1 && (
                              <span
                                className="px-3 d-flex align-items-center"
                                onClick={() => remove(index)}
                              >
                                <i
                                  className="fa fa-minus-square-o"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </span>
                            )}
                          </InputGroup>
                          <span>
                            {errors.first_name && "Name is required"}
                            {!errors.first_name &&
                              errors.email &&
                              errors.email.message}
                            {!errors.first_name &&
                              !errors.email &&
                              errors.phone &&
                              "Phone Number is required"}
                          </span>
                        </Col>
                      ))}
                      <div className="w-100">
                        <p
                          style={{ fontSize: "1rem" }}
                          className="main-theme-link px-0 float-right"
                          onClick={() => append({})}
                        >
                          Add more<i className="fa fa-plus ml-2"></i>
                        </p>
                      </div>
                      <span style={{ color: "red" }}>
                        {errors.respondants &&
                          "At least one opposite party is required"}
                      </span>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label> Name of the other parties</Label>
                    <Row form>
                      {claimFields.map(({ id }, index) => (
                        <Col sm={12} className="mb-3">
                          <InputGroup>
                            <input
                              className="form-control"
                              type="text"
                              name={`claimants[${index}].first_name`}
                              placeholder="Name"
                              ref={register}
                            />
                            <input
                              className="form-control"
                              type="email"
                              name={`claimants[${index}].email`}
                              placeholder="E-mail"
                              ref={register}
                            />
                            <input
                              className="form-control"
                              type="text"
                              name={`claimants[${index}].phone`}
                              placeholder="Mobile Number"
                              value={phone2}
                              onChange={(event) =>
                                setPhone2(event.target.value.replace(/\D/, ""))
                              }
                              ref={register}
                            />
                          </InputGroup>
                        </Col>
                      ))}
                      <div className="w-100">
                        <p
                          style={{ fontSize: "1rem" }}
                          className="main-theme-link px-0 float-right"
                          onClick={() => claimAppend({})}
                        >
                          Add more<i className="fa fa-plus ml-2"></i>
                        </p>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup className="required">
                    <Label>Background of the disputes</Label>
                    <textarea
                      className="form-control"
                      name="background"
                      ref={register({ required: true, max: "500" })}
                    ></textarea>
                    <span>
                      {errors.background &&
                        errors.background.type === "required" &&
                        "Background is required"}
                    </span>
                    <span>
                      {errors.background &&
                        errors.background.type === "max" &&
                        "Maximum 500 characters"}
                    </span>
                  </FormGroup>

                  <div className="my-4">
                    <FormGroup>
                      <Row>
                        <Col lg={8}>
                          <Label check>
                            Is there any legal dispute/case pending before any
                            Courts or forums in relation to the present dispute?
                          </Label>
                        </Col>
                        <Col lg={2} style={{ textAlign: "center" }}>
                          <Switch
                            onChange={(checked) => setExistingCase(checked)}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked={false}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    {existingCase && (
                      <>
                        <FormGroup className="required">
                          <Label textarea>Existing case details</Label>
                          <textarea
                            className="form-control"
                            name="case_exist_info"
                            ref={register({ required: true })}
                          ></textarea>
                          <span>
                            {errors.case_exist_info &&
                              errors.case_exist_info.type === "required" &&
                              "Need existing case details"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label>Existing case Documents</Label>
                          <div>
                            <Dragger {...existigCaseUploadProps}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                            </Dragger>
                          </div>
                        </FormGroup>
                      </>
                    )}
                  </div>
                </Col>
                <Col lg={5} md={12}>
                  <FormGroup className="required">
                    <Label>Claims made / Reliefs claimed</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="claims"
                      ref={register({ required: true })}
                    />
                    <span>{errors.claims && "Claims is required"}</span>
                  </FormGroup>
                  <Row form>
                    <Col className="col">
                      <FormGroup className="required">
                        <Label>Nature of Dispute</Label>{" "}
                        <i
                          className="fa fa-info-circle main-theme-link"
                          id="tool_tip"
                        ></i>
                        <select
                          name="issue_type"
                          className="form-control"
                          ref={register({ required: true })}
                          onChange={(e) =>
                            e.target.value === "other"
                              ? setOtherIssue(true)
                              : setOtherIssue(false)
                          }
                        >
                          {disputes.map((i, index) => (
                            <option value={i.value}>{i.label}</option>
                          ))}
                        </select>
                        <span>
                          {errors.issue_type && "Select one Issue type"}
                        </span>
                      </FormGroup>
                      {otherIssue && (
                        <FormGroup className="required">
                          <Label>Other Issue</Label>
                          <input
                            name="issue_type"
                            className="form-control"
                            ref={register({ required: true })}
                          />
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>Relevant Documents</Label>
                    <div>
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                    </div>
                    <Alert
                      className="mt-3"
                      message="Note: The Documents uploaded here are shared only with the neutrals and the other Parties cannot view the documents. To share the uploaded documents with the other Parties, please share the documents from your registered e-mail ID to the Neutral’s mail ID and the other Parties’ mail IDs."
                      type="warning"
                      showIcon
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Button
                  className="main-theme-btn"
                  type="submit"
                  color="primary"
                >
                  Create Case
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default CreateCase;
