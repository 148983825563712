import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.svg";

import Header from "../components/common/header-component/header";
import Sidebar from "../components/common/sidebar-component/sidebar";
import Help from "../components/common/Help";
import Footer from "../components/common/footer";
import ThemeCustomizer from "../components/common/theme-customizer";

import LoginLayout from "./UserAuthPage";
import { user_authorizer, neutral_authorizer } from "../utils/authorizer";
import { USER_MENUITEMS, NEUTRAL_MENUITEMS } from "../constant/menu";


const Layout = ({ children, auth, logout, currMenu }) => {
  return (
    <>
      {!auth ? (
        <div className="page-wrapper">
          <div className="container-fluid p-0">{children}</div>
        </div>
      ) : (
        <>
          <div className="page-body-wrapper">
            <Header logout={logout} currMenu={currMenu} />
            {user_authorizer() && <Sidebar MENUITEMS={USER_MENUITEMS} />}
            {neutral_authorizer() && <Sidebar MENUITEMS={NEUTRAL_MENUITEMS} />}
            <div className="page-body">{children}</div>
            <Footer />
            {/* <ThemeCustomizer /> */}
          </div>
        </>
      )}
    </>
  );
};

export default Layout
