import React, { Fragment } from 'react';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
} from 'react-switch-lang';
import en from '../../../assets/i18n/en.json';
import ta from "../../../assets/i18n/ta.json";


setTranslations({ en, ta });
setDefaultLanguage('en');
setLanguageCookie();

const Language = () => {
    const handleSetLanguage = (key) => {
        setLanguage(key);
    };
    return (
      <Fragment>
        <div>
          <ul className="language-dropdown onhover-show-div p-20 d-flex flex-column">
            <li onClick={() => handleSetLanguage("en")}>
              <a href="#javascript" data-lng="en">
                <i className="flag-icon flag-icon-is"></i> English
              </a>
            </li>
            {/* <li onClick={() => handleSetLanguage("ta")}>
              <a href="#javascript" data-lng="ta">
                <i className="flag-icon flag-icon-um"></i> தமிழ்
              </a>
            </li> */}
          </ul>
        </div>
      </Fragment>
    );
};


export default translate(Language);