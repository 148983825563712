import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
  Col,
} from "reactstrap";

import logo from "../../assets/images/logo.svg";

import { neutral_login } from "../../services/neutral_api";

const Login = ({ authenticate }) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const onNeutralLogin = async (data) => {
    const res = await neutral_login(data);
    if (res) {
      if (res.status) {
         localStorage.setItem("neutral_name", res.data.first_name.toLowerCase());
         localStorage.setItem("neutral", res.data.token);
         localStorage.setItem("id", res.data.id);
         authenticate();
         history.replace("/neutral/dashboard");
        }
      }
  };

  return (
    <Col lg={9} xl={8} className="auth col-11">
      <div className="mb-4 ">
        <h3 className="font-weight-bold">Neutral Login</h3>
      </div>
      <Form
        noValidate
        className=" theme-form validation"
        onSubmit={handleSubmit(onNeutralLogin)}
      >
        <FormGroup className="required">
          <Label>E-mail</Label>
          <input
            className="form-control"
            type="email"
            name="username"
            ref={register({
              required: "Enter your e-mail",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Enter a valid e-mail address",
              },
            })}
          />
          <span>{errors.username && errors.username.message}</span>
        </FormGroup>
        <FormGroup className="required">
          <Label>Password</Label>
          <input
            className="form-control"
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          <span>{errors.password && "Password is required"}</span>
        </FormGroup>
        <FormGroup>
          <Button
            className="main-theme-btn"
            block
            type="submit"
            color="primary"
          >
            Login
          </Button>
        </FormGroup>
        <FormGroup>
          <Link to="/neutral/signup">Create new account</Link>
        </FormGroup>
      </Form>
    </Col>
  );
};

export default Login;
