import React, { useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { CardBody, Col, Row, Card, Container } from "reactstrap";
import { Briefcase, CheckSquare } from "react-feather";
import Calendar from "./NeutralCalendar";

import { getAllCases } from "../../services/neutral_api";

const NeutralDashboard = () => {
  const [cases, setCases] = useState(0);

  useEffect(() => {
    getAllCases().then((res) => {
      if (res) {
        if (res.status) {
          setCases(res.data.length);
        }
      }
    });
  }, []);

  return (
    <div>
      <Breadcrumb title="Dashboard" />

      <Container fluid>
        <Row>
          <Col lg={7}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="card o-hidden mb-0">
                      <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <Briefcase />
                          </div>
                          <div className="media-body">
                            <span className="m-0">Cases</span>
                            <h4
                              className="mb-0 counter"
                              style={{ color: "white" }}
                            >
                              {cases}
                            </h4>
                            <Briefcase className="icon-bg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="card o-hidden mb-0">
                      <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <CheckSquare />
                          </div>
                          <div className="media-body">
                            <span className="m-0">Resolved Cases</span>
                            <h4
                              className="mb-0 counter"
                              style={{ color: "white" }}
                            >
                              0
                            </h4>
                            <CheckSquare className="icon-bg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={5}>
            <Card>
              <CardBody>
                <Calendar />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NeutralDashboard;
