import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "antd/lib/date-picker/style/index.css";
import moment from "moment";
import Tag from "../common/Tag";

import { subString, checkLoggedInUser } from "../../utils/util";


const HearingModal = ({ open, toggle, hearing, id }) => {
  return (
    <>
      {hearing && (
        <Modal isOpen={open} toggle={toggle} centered>
          <ModalHeader toggle={toggle}>
            <div className="d-flex align-items-center">
              {hearing.agenda}
              <Tag className="ml-3 f-12" status={hearing.status} />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <h6>
                Date: {moment(hearing.date).format("DD/MM/YYYY")}{" "}
                {moment(hearing.date).format("hh:mm: A")}
              </h6>
              <p>{hearing.description}</p>
              <div className="pt-3">
                <p className="mb-1 text-black-50">
                  Meeting Link:{" "}
                  {checkLoggedInUser() === "user" ? (
                    <a target="_blank" href={`${hearing.meeting_link}`}>
                      {subString(hearing.meeting_link, 35)}
                    </a>
                  ) : (
                    <a target="_blank" href={`${hearing.start_url}`}>
                      {subString(hearing.start_url, 35)}
                    </a>
                  )}
                </p>
                {hearing.mom && (
                  <p>
                    <strong>Minutes of Meeting:</strong> {hearing.mom}
                  </p>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button outline color="danger" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default HearingModal;
