import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { getAllHearings as userHearings } from "../../services/user_api";
import { getAllHearings as neutralHearings } from "../../services/neutral_api";
import Breadcrumb from "../common/breadcrumb";
import moment from "moment";
import { getColor, subString, checkLoggedInUser } from "../../utils/util";
import Tag from "../common/Tag";
import HearingModal from "./HearingModal";

const AllHearings = ({ user, neutral }) => {
  const { id } = useParams();
  const [hearings, setHearings] = useState([]);
  const [hearing, setHearing] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (user) {
      userHearings(id).then((res) => {
        if (res.status) {
          setHearings(res.data);
        }
      });
    } else if (neutral) {
      neutralHearings(id).then((res) => {
        if (res.status) {
          setHearings(res.data);
        }
      });
    }
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      {user && (
        <Breadcrumb
          grand_parent="Cases"
          grand_parent_url="/user/cases"
          parent="Case Detail"
          parent_url={`/user/cases/${id}`}
          title="Hearings"
        />
      )}
      {neutral && (
        <Breadcrumb
          grand_parent="Your cases"
          grand_parent_url="/neutral/cases"
          parent="Case Detail"
          parent_url={`/neutral/cases/${id}`}
          title="Hearings"
        />
      )}
      <Container fluid>
        <HearingModal open={modal} toggle={toggle} hearing={hearing} />
        <Card>
          <CardBody className="recent-notification">
            <h5 className="mb-3">Hearings</h5>
            <div>
              {hearings.length > 0 &&
                hearings.map((h) => (
                  <>
                    <div
                      className="media hearing-item"
                      onClick={() => {
                        setHearing(h);
                        setModal(true);
                      }}
                    >
                      <h6>{moment(h.date).format("DD-MM-YYYY")}</h6>
                      <div className={`media-body ${getColor(h.status)}`}>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="f-w-600">{h.agenda}</span>
                          <Tag status={h.status} />
                        </div>
                        <p className="m-0">
                          Meeting Time: {moment(h.date).format("hh:mm A")}
                        </p>
                        <p className="mb-2">
                          Meeting Link:{" "}
                          {checkLoggedInUser() === "user" ? (
                            <a target="_blank" href={`${h.meeting_link}`}>
                              {subString(h.meeting_link, 35)}
                            </a>
                          ) : (
                            <a target="_blank" href={`${h.start_url}`}>
                              {subString(h.start_url, 35)}
                            </a>
                          )}
                        </p>
                        <p className="f-12">{subString(h.description, 100)}</p>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AllHearings;
