import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  forgot_password_user,
  set_reset_user_password,
} from "../../services/user_api";
import { hideEmail } from "../../utils/util";
import logo from "../../assets/images/logo.svg";

const UserForgotPass = () => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    setValue,
  } = useForm();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState();
  const [newPassForm, setNewPassForm] = useState(false);

  const sendOtp = async (data) => {
    setLoading(true);
    const res = await forgot_password_user(data);
    if (res) {
      if (res.status) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoading(false);
        setEmail(data.user_name);
        setNewPassForm(true);
      } else {
        setLoading(false);
      }
    }
  };

  const changePass = async (data) => {
    const otp = data.otp_1 + data.otp_2 + data.otp_3 + data.otp_4;
    setLoading(true);
    const res = await set_reset_user_password({
      otp_code: otp,
      user_name: email,
      password: data.password,
    });
    if (res) {
      if (res.status) {
        setLoading(false);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        history.replace("/user/login");
      } else {
        setLoading(false);
      }
    }
  };

  const confirmPassword = (e) => {
    if (e === pass) return true;
    else return false;
  };

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 4) {
        const nextSibling = document.querySelector(
          `input[name=otp_${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  return (
    <>
      {!newPassForm ? (
        <Col lg={9} xl={8} className="auth col-11">
          <div className="mb-4 ">
            <h3 className="font-weight-bold mb-3">Update Password</h3>
            <p className="text">
              Enter the email address associated with your account
            </p>
          </div>
          <Form
            noValidate
            className=" theme-form validation"
            onSubmit={handleSubmit(sendOtp)}
          >
            <FormGroup>
              <Label hidden>E-mail</Label>
              <input
                className="form-control"
                type="email"
                name="user_name"
                placeholder="E-mail"
                ref={register({
                  required: "Enter your e-mail",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
              />
              <span>{errors.user_name && errors.user_name.message}</span>
            </FormGroup>

            <FormGroup>
              <Button
                block
                className="main-theme-btn"
                type="submit"
                color="primary"
                disabled={loading}
              >
                Send Veification Code
              </Button>
            </FormGroup>
            <FormGroup>
              <Link to="/user/login">Back to Login</Link>
            </FormGroup>
          </Form>
        </Col>
      ) : (
        <Col lg={9} xl={8} className="auth col-11">
          <div className="mb-4 ">
            <h3 className="font-weight-bold mb-3">Validate OTP</h3>
            <p className="text">
              Please enter the OTP (one time password) to verify your account. A
              Code has been sent to {hideEmail(email)}
            </p>
          </div>
          <Form
            noValidate
            className=" theme-form validation"
            onSubmit={handleSubmit2(changePass)}
          >
            <FormGroup>
              <Label hidden>OTP</Label>
              <Row form>
                <Col xs={3}>
                  <input
                    className="form-control text-center"
                    maxLength="1"
                    type="text"
                    name="otp_1"
                    onChange={handleChange}
                    ref={register2({ required: true })}
                  />
                </Col>
                <Col xs={3}>
                  <input
                    className="form-control text-center"
                    maxLength="1"
                    type="text"
                    name="otp_2"
                    onChange={handleChange}
                    ref={register2({ required: true })}
                  />
                </Col>
                <Col xs={3}>
                  <input
                    className="form-control text-center"
                    maxLength="1"
                    type="text"
                    name="otp_3"
                    onChange={handleChange}
                    ref={register2({ required: true })}
                  />
                </Col>
                <Col xs={3}>
                  <input
                    className="form-control text-center"
                    maxLength="1"
                    type="text"
                    name="otp_4"
                    ref={register2({ required: true })}
                  />
                </Col>
              </Row>

              <span>
                {errors.otp_1 &&
                  errors.otp_2 &&
                  errors.otp_3 &&
                  errors.otp_4 &&
                  "OTP is required!"}
              </span>
            </FormGroup>
            <FormGroup>
              <Label>New Password</Label>
              <input
                className="form-control"
                type="password"
                name="password"
                autoComplete="new-password"
                onChange={(e) => setPass(e.target.value)}
                ref={register2({ required: true })}
              />
              <span>{errors2.password && "Password is required"}</span>
            </FormGroup>
            <FormGroup>
              <Label>Confirm New Password</Label>
              <input
                className="form-control"
                type="password"
                name="confirm_pass"
                ref={register2({
                  required: true,
                  validate: { confirmPassword },
                })}
              />
              {errors2.confirm_pass &&
                errors2.confirm_pass.type === "required" && (
                  <span>Confirm Password is required</span>
                )}
              {errors2.confirm_pass &&
                errors2.confirm_pass.type !== "required" && (
                  <span>Passwords do not match</span>
                )}
            </FormGroup>
            <FormGroup>
              <Button
                className="main-theme-btn"
                block
                color="primary"
                type="submit"
              >
                Update Password
              </Button>
            </FormGroup>
            <FormGroup>
              <Link to="/user/login">Back to Login</Link>
            </FormGroup>
          </Form>
        </Col>
      )}
    </>
  );
};

export default UserForgotPass;
