const disputes = [
  {
    label: "",
    value: "",
  },
  {
    label: "Commercial and business disputes",
    value: "Commercial and business disputes",
  },
  {
    label: "Construction and Real estate disputes",
    value: "Construction and Real estate disputes",
  },
  {
    label: "Family disputes including marital and property matters",
    value: "Family disputes including marital and property matters",
  },
  {
    label: "Consumer disputes",
    value: "Consumer disputes",
  },
  {
    label: "Banking, Finance and Insurance disputes",
    value: "Banking, Finance and Insurance disputes",
  },
  {
    label: "Employment and labour disputes",
    value: "Employment and labour disputes",
  },
  {
    label: "Disputes concerning Intellectual Property Rights",
    value: "Disputes concerning Intellectual Property Rights",
  },
  {
    label: "Media, Technology and cyber disputes",
    value: "Media, Technology and cyber disputes",
  },
  {
    label: "Sports disputes",
    value: "Sports disputes",
  },
  {
    label: "Disputes relating to associations, trusts and societies etc",
    value: "Disputes relating to associations, trusts and societies etc",
  },
  {
    label:
      "Disputes relating to Motor vehicle accident claims and such other disputes of civil and compoundable nature",
    value:
      "Disputes relating to Motor vehicle accident claims and such other disputes of civil and compoundable nature",
  },
  {
    label: 'Other',
    value:'other'
  }
];

const contract_method = [
  {
    label: "Mediation",
    value: "Mediation",
  },
  {
    label: "Conciliation",
    value: "Conciliation",
  },
  {
    label: "Arbitration",
    value: "Arbitration",
  },
  {
    label: "Hybrid Clauses",
    value: "Hybrid Clauses",
  },
];

export { disputes, contract_method };
