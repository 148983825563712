import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { user_login } from "../../services/user_api";
import { Form, FormGroup, Label, Row, Col, Button } from "reactstrap";


const Login = ({authenticate }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors } = useForm();

  const onUserLogin = async (data) => {
      setLoading(true)
      const res = await user_login(data)
      if (res) {
        if (res.status) {
          localStorage.setItem("user_name", res.data.first_name.toLowerCase());
          localStorage.setItem("user", res.data.token);
          localStorage.setItem("id", res.data.id);
          authenticate();
          history.replace("user/dashboard");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } 
  };

  return (
    <Col lg={9} xl={8} className="auth col-11">
      <div className="mb-4 ">
        <h3 className="font-weight-bold">User Login</h3>
      </div>
      <Form
        noValidate
        className=" theme-form validation"
        onSubmit={handleSubmit(onUserLogin)}
      >
        <FormGroup className="required">
          <Label>E-mail</Label>
          <input
            className="form-control"
            type="email"
            name="username"
            ref={register({
              required: "Enter your e-mail",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Enter a valid e-mail address",
              },
            })}
          />
          <span>{errors.username && errors.username.message}</span>
        </FormGroup>
        <FormGroup className="required">
          <Label>Password</Label>
          <input
            className="form-control"
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          <span>{errors.password && "Password is required"}</span>
        </FormGroup>

        <div className="d-flex justify-content-between">
          <FormGroup>
            <Link to="/user/signup">Create new account</Link>
          </FormGroup>
          <FormGroup>
            <Link to="/user/forgot_password">Forgot Password?</Link>
          </FormGroup>
        </div>
        <Row form className="d-flex flex-column align-items-center ">
          <Col sm={12}>
            <FormGroup>
              <Button
                className="main-theme-btn"
                block
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading && <span id="loading "></span>}
                Login
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}

export default Login; 
