import React, { Fragment } from 'react';
import user from '../../../assets/images/avatar.jpg';
import { LogOut } from "react-feather";
import {Button} from "reactstrap"
import { useHistory } from "react-router-dom";
import { checkLoggedInUser } from "../../../utils/util";


const UserMenu = ({ logout }) => {
  const history = useHistory();

  const handleLogOut = () => {
    const currUser = checkLoggedInUser();
    localStorage.removeItem("user");
    localStorage.removeItem("id");
    localStorage.removeItem("neutral");
    logout();
    if (currUser === "user") {
      history.replace("/user/login");
    }
    if (currUser === "neutral") {
      history.replace("/neutral/login");
    }
  };


  return (
    <Fragment>
      <Button onClick={handleLogOut} color="danger">Log out</Button>
        {/* <img
          className="align-self-center pull-right img-40 rounded-circle blur-up lazyloaded"
          src={user}
          alt="header-user"
        /> */}

      {/* <li className="onhover-dropdown">
          <div className="media align-items-center"></div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <a href="#javascript">
                <User />
                Edit Profile
              </a>
            </li>
            <li>
              <a href="#javascript">
                <Mail />
                Inbox
              </a>
            </li>
            <li>
              <a href="#javascript">
                <Lock />
                Lock Screen
              </a>
            </li>
            <li>
              <a href="#javascript">
                <Settings />
                Settings
              </a>
            </li>
            <li>
              <a onClick={handleLogOut}>
                <LogOut /> Log out
              </a>
            </li>
          </ul>
        </li> */}
    </Fragment>
  );
};


export default UserMenu;