import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Button,
} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";

import { subString, displayName } from "../../utils/util";
import { show_case, paymentCallback } from "../../services/user_api";
import config from "../../utils/config.json";

const Billing = () => {
  const { id } = useParams();
  const history = useHistory();
  const [userCase, setUserCase] = useState();
  const [payment, setPayment] = useState();

  useEffect(() => {
    show_case(id).then((res) => {
      if (res) {
        if (res.status) {
          setUserCase(res.data);
        }
      }
      const arr = res.data.payments.filter((p) => p.status === "created");
      setPayment(...arr);
    });
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: config.RAZORPAY_KEY,
      currency: "INR",
      amount: payment.amount,
      order_id: payment.order_id,
      name: "The Settlement Table",
      description: "  ",
      image: `http://${window.location.host}/logo.svg`,
      handler: async function (response) {
        const res = await paymentCallback(payment.id, {
          order_id: response.razorpay_order_id,
          payment_id: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        });
        if (res) {
          if (res.status) {
            if (res.data.status === "approved") {
              history.replace({
                pathname: `/user/cases/${id}/payment_success`,
                state: {
                  payment_id: res.data.payment_id,
                },
              });
            }
          }
        }
      },
      // prefill: {
      //   name: "User name",
      //   email: "sdfdsjfh2@ndsfdf.com",
      //   phone_number: "9899999999",
      // },
      theme: {
        color: "#28a745",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", (err) => {
      history.replace({
        pathname: `/user/cases/${id}/payment_fail`,
        state: {
          payment_id: err.error.metadata.payment_id,
        },
      });
    });
    rzp1.on("payment.captured", (event) => {});
  }

  return (
    <>
      <Breadcrumb
        grand_parent="Cases"
        grand_parent_url="/user/cases"
        parent="Case Detail"
        parent_url={`/user/cases/${id}`}
        title="Billing"
      />
      <Container fluid>
        {userCase && payment && (
          <Card>
            <CardBody className="p-5">
              <h4 className="f-w-600 bill_heading mb-5">{userCase.subject}</h4>
              <Row className="d-flex justify-content-around my-4">
                <Col xl={6} md={12} className="mx-auto">
                  <div className="bill_summary">
                    <h5 className="mb-3 font-weight-bold">Summary</h5>
                    <div className="divider"></div>
                    <p>{payment.remarks}</p>
                    {/* <div>
                      {BILL_ITEM.map((i) => (
                        <div className="d-flex justify-content-between mb-2">
                          <h6 className="font-weight-light">{i.name}</h6>
                          <h6 className="font-weight-light">
                            <span>₹</span>
                            {i.price.toFixed(2)}
                          </h6>
                        </div>
                      ))}
                    </div> */}
                    <div className="divider"></div>
                    <div>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Administrative Cost:</h6>
                        <h6>
                          <span>₹</span>
                          {parseInt(payment.split_up.commission).toFixed(2)}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Neutral Fees:</h6>
                        <h6>
                          <span>₹</span>
                          {parseInt(payment.split_up.neutral_fee).toFixed(2)}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>GST:</h6>
                        <h6>
                          <span>₹</span>
                          {parseInt(payment.split_up.gst).toFixed(2)}
                        </h6>
                      </div>
                      {/* <div className="d-flex justify-content-between mb-2">
                        <p>CGST({CGST_PERCENT}%):</p>
                        <p>
                          <span>₹</span>
                          {CGST_AMOUNT.toFixed(2)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p>SGST({SGST_PERCENT}%):</p>
                        <p>
                          <span>₹</span>
                          {SGST_AMOUNT.toFixed(2)}
                        </p>
                      </div> */}
                    </div>
                    <div className="divider"></div>
                    <div className="d-flex justify-content-between bill_total">
                      <h5 className="f-w-600">Total</h5>
                      <h5 className="f-w-600">
                        <span className="price">
                          ₹{payment.amount.toFixed(2)}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <Button
                    className="main-theme-btn mt-5"
                    block
                    onClick={displayRazorpay}
                  >
                    Pay with Razorpay
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default Billing;
