import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Alert, Radio } from "antd";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";

import { getAllHearings } from "../../services/user_api";
import {
  completeHearing,
  neutralQuery,
  getAllHearings as neutralHearings,
} from "../../services/neutral_api";

const DynamicAlert = ({ userType, case_id }) => {
  const [nowTime, setNowTime] = useState(
    "Mon Mar 22 2021 14:19:00 GMT+0530 (India Standard Time)"
  );
  const [hearing, setHearing] = useState();
  const [modal, setModal] = useState();
  const [action, setAction] = useState("finish");
  const { register, handleSubmit, errors } = useForm();
  const [alert, setAlert] = useState({
    status: false,
    message: "",
    link: "",
  });

  useEffect(() => {
    getEvents();
    return clearInterval(interval);
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  const fetchTodayMeeting = async () => {
    let res;
    if (userType === "user") {
      res = await getAllHearings(case_id);
    }
    if (userType === "neutral") {
      res = await neutralHearings(case_id);
    }
    if (res) {
      if (res.status) {
        const todaysMeeting = res.data.filter(
          (meet) => now.isSame(meet.date, "day") && meet.status === "scheduled"
        );
        return todaysMeeting;
      }
    }
  };


  const checkUpcommingMeet = async () => {
    const todaysMeeting = await fetchTodayMeeting()
    let upCommingMeet;
    todaysMeeting.map(meet => {
      const now = moment();
      const diff = getDifference(now, meet.date);
      if (diff > 60 || diff < 60) {
        upCommingMeet = meet
      }
    })
    return upCommingMeet
  }

  const getDifference = (now, meeting) => {
    return now.diff(moment(meeting), "minutes");
  };

  const checkTime = (meeting, meetingLink, startLink) => {
    const now = moment();
    const diff = getDifference(now, meeting);
    if (userType === "user") {
      if (diff > -1 && diff < 30) {
        setAlert({
          status: true,
          message:
            "Dear user you have an ongoing meeting! Click here to join the meeting. Make sure that you have secure and steady internet connection and power supplies. ",
          button: {
            text: "Join Meeting",
            link: meetingLink,
          },
        });
      } /* else {
        clearInterval(interval);
      } */
    }

    if (userType === "neutral") {
      if (diff > -1 && diff < 5) {
        setAlert({
          status: true,
          message:
            "Dear neutral you have a meeting right now, Click to start the meeting",
          button: {
            text: "Start Meeting",
            link: startLink,
          },
        });
      }
      if (diff > 5 && diff < 200) {
        setAlert({
          status: true,
          message: "Say what happend in the meeting?",
          button: {
            text: "Add Minutes of Meeting",
            toggle: toggleModal,
          },
        });
       
      }/*  else {
         clearInterval(interval);
      } */
    }
  };

  const checkTimeOnce = (meeting, meetingLink, startLink) => {
    const now = moment();
    const diff = getDifference(now, meeting);
    if (userType === "user") {
      if (diff > -1 && diff < 30) {
        setAlert({
          status: true,
          message:
            "Dear user you have an ongoing meeting! Click here to join the meeting. Make sure that you have secure and steady internet connection and power supplies. ",
          button: {
            text: "Join Meeting",
            link: meetingLink,
          },
        });
      }
       if (diff > 30) {
         clearInterval(interval);
       }
    }

    if (userType === "neutral") {
      if (diff > -1 && diff < 5) {
        setAlert({
          status: true,
          message: "Dear neutral you have a meeting right now",
          button: {
            text: "Start Meeting",
            link: startLink,
          },
        });
      }
      if (diff > 5 && diff < 200) {
        setAlert({
          status: true,
          message: "Say what happend in the meeting?",
          button: {
            text: "Add Minutes of Meeting",
            toggle: toggleModal,
          },
        });
      }
      if (diff > 200) {
        clearInterval(interval)
      }
    }
  };

  let interval;
  const now = moment();
  const getEvents = async () => {
    const todayMeeting = await checkUpcommingMeet();
    if (todayMeeting) {
     setHearing(todayMeeting);
     const meeting = todayMeeting.date;
     const meetingLink = todayMeeting.meeting_link;
     const startLink = todayMeeting.start_url;
     const diff = getDifference(now, meeting);
     if (diff > -30) {
       interval = setInterval(() => {
         checkTime(meeting, meetingLink, startLink);
       }, 10000);
       checkTimeOnce(meeting, meetingLink, startLink);
     }
   }
  };

  const onSubmit = async (data) => {
    if (action === "finish") {
      const payload = {
        case_hearing_id: hearing.id,
        mom: data.message,
      };
      const res = await completeHearing(case_id, payload);
      if (res) {
        if (res.status) {
          toast.success("MOM saved successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggleModal();
        }
      }
    }

    if (action === "cancel") {
      const neutral_id = localStorage.getItem("id");
      const payload = {
        message: data.message,
        category: "case_query",
        person_id: neutral_id,
      };
      const res = await neutralQuery(payload);
      if (res) {
        if (res.status) {
          toast.success("Query sent successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggleModal();
        }
      }
    }
  };

  return (
    <div className="mb-2">
      {alert.status && (
        <Alert
          message={alert.message}
          className="mt-2"
          type="success"
          action={
            <>
              {alert.button.link ? (
                <a href={alert.button.link} target="_blank">
                  <Button size="sm" color="success">
                    {alert.button.text}
                  </Button>
                </a>
              ) : (
                <Button size="sm" color="success" onClick={alert.button.toggle}>
                  {alert.button.text}
                </Button>
              )}
            </>
          }
        />
      )}

      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          <div className="d-flex align-items-center">
            {action === "finish" ? " Add Minutes of Meeting" : "Raise query"}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Form onSubmit={handleSubmit(onSubmit)} id="mom">
              <FormGroup>
                <Radio.Group
                  onChange={(e) => setAction(e.target.value)}
                  value={action}
                >
                  <Radio value="finish">Meeting Finished</Radio>
                  <Radio value="cancel">Cancel or Reschedule meeting</Radio>
                </Radio.Group>
              </FormGroup>
              <FormGroup>
                <Label>
                  {action === "finish"
                    ? "Add minutes of meeting for the hearing"
                    : "Message"}
                </Label>
                <textarea
                  className="form-control"
                  name="message"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline color="danger" onClick={toggleModal}>
            Close
          </Button>
          <Button outline color="success" type="submit" form="mom">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DynamicAlert;
