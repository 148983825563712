import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import { Col, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";

import { getCalendarEvents } from "../../services/user_api";
import moment from "moment";

const UserCalendar = () => {
  let calendarComponentRef = useRef();
  const [cal, setCal] = useState({
    calendarEvents: [],
    events: [],
  });

  useEffect(() => {
    let button = window.document.getElementsByClassName("fc-button");

    button[1].addEventListener("click", () => {
      let month;
      let year;
      setTimeout(() => {
        const ref = calendarComponentRef.current._calendarApi.view.currentStart;
        month = moment(ref).get("months") + 1;
        year = moment(ref).get("year");
        getEvents(month, year);
      }, 100);
    });
    button[2].addEventListener("click", () => {
      let month;
      let year;
      setTimeout(() => {
        const ref = calendarComponentRef.current._calendarApi.view.currentStart;
        month = moment(ref).get("months") + 1;
        year = moment(ref).get("year");
        getEvents(month, year);
      }, 100);
    });

    return (
      button[1].removeEventListener("click", () => {}),
      button[2].removeEventListener("click", () => {})
    );
  }, []);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async (month, year) => {
    const res = await getCalendarEvents(month, year);
    if (res) {
      if (res.status) {
        let events = res.data;
        const eventArr = [];
        for (let key in events) {
          eventArr.push(...events[key]);
        }
        setCal({
          calendarEvents: eventArr.map((e, i) => {
            return {
              title: e.subject,
              start: e.datetime,
              id: i,
              extendedProps: {
                meeting_link: e.meeting_link,
              },
            };
          }),
        });
      }
    }
  };

  const eventClick = (eventClick) => {
    Swal.fire({
      title: eventClick.event.title,
      html:
        `<div class="table-responsive" style="font-size:1rem">
      <table class="table">
      <tbody>
      <tr >
      <td style="text-align:left">Title</td>
      <td style="text-align:right"><strong>` +
        eventClick.event.title +
        `</strong></td>
      </tr>
      <tr >
      <td  style="text-align:left">Start Time</td>
      <td style="text-align:right"><strong>
      ` +
        moment(eventClick.event.start).format("hh:mm:a") +
        `
      </strong></td>
      </tr>
      <tr >
      <td  style="text-align:left">Meeting Link</td>
      <td  style="text-align:right">
      ` +
        `<a href="${eventClick.event.extendedProps.meeting_link}" target="_blank">Click to join</a>` +
        `
      </td>
      </tr>
      </tbody>
      </table>
      </div>`,
      showCancelButton: true,
      showConfirmButton: false,
      // confirmButtonColor: "#28a745",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Close",
    });
  };

  return (
    <Fragment>
      <div className="animated fadeIn demo-app">
        {/* <div id="external-events">
              {cal.events.map((event) => (
                <div
                  className="fc-event"
                  title={event.title}
                  data={event.id}
                  key={event.id}
                >
                  {event.title}
                </div>
              ))}
            </div> */}
        <div className="demo-app-calendar" id="mycalendartest">
          <FullCalendar
            initialView="dayGridMonth"
            header={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            rerenderDelay={10}
            eventDurationEditable={false}
            editable={true}
            droppable={true}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={calendarComponentRef}
            weekends={cal.calendarWeekends}
            events={cal.calendarEvents}
            // eventDrop={this.drop}
            // eventReceive={this.eventReceive}
            eventClick={eventClick}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default UserCalendar;
