import decode from "jwt-decode";

export const user_authorizer = () => {
  try {
    let token = localStorage.getItem("user");
    if (!token) {
      return false;
    }
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
};

export const neutral_authorizer = () => {
  try {
    let token = localStorage.getItem("neutral");
    if (!token) {
      return false;
    }
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
};
