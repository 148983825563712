import React, { Fragment, useState, useEffect } from "react";

import logo from "../../assets/images/logo.svg";

const Loader = () => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        setTimeout(() => {
          setShow(false);
        }, 1000);
        
    },[show]);
    return (
      <Fragment>
        <div className={`loader-wrapper ${show ? "" : "loderhide"}`}>
          <div className="loader bg-white" style={{ top: "40%" }}>
            {/* <div className="whirly-loader"> </div> */}
            <img
              src={logo}
              style={{ width: "8vw", height: "8vw" }}
              className="loader-img"
              alt="Loading"
            />
          </div>
        </div>
      </Fragment>
    );
};

export default Loader;