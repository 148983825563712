import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Row, Col } from "reactstrap";

import logo from "../assets/images/logo_light.svg";
import config from "../utils/config.json";

const UserAuthPage = ({ children, action }) => {
  const [username, setUsername] = useState("User");
  useEffect(() => {
    const user = localStorage.getItem("user_name");
    if (user) setUsername(user);
  }, []);

  return (
    <Row noGutters className="auth__page">
      <Col
        md={6}
        className="auth__container--left d-flex justify-content-between flex-column"
      >
        <Row noGutters className="">
          <Col lg={9} className="mx-auto  col-10">
            <a href={config.LANDING_PAGE_URL}>
              <img className="logo mt-5" src={logo} alt="logo" />
            </a>
          </Col>
        </Row>
        <Row noGutters className="my-auto">
          <Col lg={9} className="auth__content mx-auto col-10">
            {action === "login" ? (
              <>
                <h1 className="head">
                  Welcome back!
                  <br /> <span className="username">{username}</span>
                </h1>
                <p className="text">
                  Login and start registering you disputes online
                </p>
              </>
            ) : (
              <>
                <h1 className="head" >Looks like you're new here!</h1>
                <p className="text">
                  Signup and solve your legal disputes with expert professionals
                </p>
              </>
            )}
          </Col>
        </Row>
        <div></div>
      </Col>
      <Col md={6} className=" d-flex align-items-center auth__container--right">
        <div className="container my-auto py-5 ">
          <Row noGutters>{children}</Row>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(UserAuthPage);
