import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Label,
  FormGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { userQuery } from "../../services/user_api"
import { neutralQuery } from "../../services/neutral_api";

const RaiseQuery = ({ modal, toggle }) => {
  const { register, handleSubmit, errors } = useForm();

  const handleQuery = async (data) => {
    const person_id = localStorage.getItem("id");
    const user = localStorage.getItem('user');
    const neutral = localStorage.getItem('neutral')
    let payload;
    let res;
    if (user) {
      payload = { ...data, person_id, category: "user_complaint" };
       res = await userQuery(payload);
    } else if (neutral) {
      payload = { ...data, person_id, category: "neutral_complaint" };
      res = await neutralQuery(payload);
    }
    
    if (res) {
      if (res.status) {
        toast.success("Query created successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Raise Query</ModalHeader>
        <ModalBody>
          <Form
            id="neutral_comment_form"
            className="validation"
            onSubmit={handleSubmit(handleQuery)}
          >
            {/* <FormGroup>
              <Label>Subject</Label>
              <input
                className="form-control"
                type="text"
                name="subject"
                ref={register({ required: true })}
              />
              <span>{errors.subject && "Subject cannot be empty!"}</span>
            </FormGroup> */}
            <FormGroup>
              <Label>Message</Label>
              <textarea
                className="form-control"
                name="message"
                ref={register({required:true})}
                rows="5"
              />
              <span>{errors.message && 'Message is required!'}</span>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle} className="btn btn-outline-danger">
            Cancel
          </button>
          <Button color="success" type="submit" form="neutral_comment_form">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RaiseQuery;
