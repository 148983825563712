import React,{Fragment} from 'react';
import { Users, Download, AlertCircle } from "react-feather";
import moment from "moment";

const Notification = props => {
    return (
      <Fragment>
        <div>
          <ul
            className="notification-dropdown onhover-show-div p-0"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <li>
              Notification{" "}
              <span className="badge badge-pill badge-primary pull-right">
                {props.notification && props.notification.length}
              </span>
            </li>
            {props.notification?.length > 0 && (
              <>
                {props.notification?.map((meeting) => (
                  <li>
                    <div className="media">
                      <div className="media-body d-flex align-items-start">
                        <span>
                          <Users />
                        </span>
                        <div>
                          <p className="mt-0 text-bold f-w-600">
                            You have a meeting on the case {meeting.subject}
                          </p>
                          <p className="mb-0">
                            Time: {moment(meeting.datetime).format("hh:mm:a")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </>
            )}
            {props.notification?.length === 0 && (
              <li>
                <p>You have no new notifications</p>
              </li>
            )}
          </ul>
        </div>
      </Fragment>
    );
};

export default Notification;