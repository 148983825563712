import request from "../utils/request";
import config from "../utils/config.json";

export async function user_login({ username, password }) {
  return request(`${config.BASE_URL}/v1/login`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      user_name: username,
      password,
    },
  });
}

export async function user_signup(data) {
  return request(`${config.BASE_URL}/v1/signup`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export async function set_reset_user_password(data) {
  return request(`${config.BASE_URL}/v1/reset-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export async function forgot_password_user(data) {
  return request(`${config.BASE_URL}/v1/forgot-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export async function all_cases() {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/v1/case_files`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function create_case(data) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/v1/case_files`, {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function show_case(id) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/v1/case_files/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function getAllHearings(case_id) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/v1/case_files/${case_id}/hearings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function getComments(case_id, page, per_page) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/v1/case_files/${case_id}/comments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params: {
      page,
      per_page,
    },
  });
}

export async function addComment(case_id, data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/v1/case_files/${case_id}/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function getCalendarEvents(month, year) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/v1/case_files/calender${
      month && year ? `/?month=${month}&year=${year}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
}

export async function userQuery(data) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/v1/contact_queries/contact_us`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function paymentCallback(payment_id, data) {
  const token = localStorage.getItem("user");
  return request(
    `${config.BASE_URL}/v1/case_files/${payment_id}/payment_callback`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: {
        payments: data,
      },
    }
  );
}


