import request from "../utils/request";
import config from "../utils/config.json";

export async function neutral_login({ username, password }) {
  return request(`${config.BASE_URL}/neutrals/v1/login`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      user_name: username,
      password,
    },
  });
}

export async function neutral_signup(data) {
  return request(`${config.BASE_URL}/neutrals/v1/signup`, {
    method: "PUT",
    data: data,
  });
}

export async function set_reset_neutral_password(data) {
  return request(`${config.BASE_URL}/neutrals/v1/reset-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export async function caseDetail(case_id) {
  const token = localStorage.getItem("neutral");

  return request(`${config.BASE_URL}/neutrals/v1/case_files/${case_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function getAllHearings(case_id) {
  const token = localStorage.getItem("neutral");

  return request(
    `${config.BASE_URL}/neutrals/v1/case_files/${case_id}/hearings`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
}

export async function getAllCases(params) {
  const token = localStorage.getItem("neutral");

  return request(`${config.BASE_URL}/neutrals/v1/case_files`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params,
  });
}


export async function getComments(case_id, page, per_page) {
  const token = localStorage.getItem("neutral");

  return request(
    `${config.BASE_URL}/neutrals/v1/case_files/${case_id}/comments`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      params: {
        page,
        per_page,
      },
    }
  );
}

export async function addComment(case_id, data) {
  const token = localStorage.getItem("neutral");

  return request(
    `${config.BASE_URL}/neutrals/v1/case_files/${case_id}/comment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: data,
    }
  );
}

export async function getCalendarEvents(month, year) {
  const token = localStorage.getItem("neutral");

  return request(
    `${config.BASE_URL}/neutrals/v1/case_files/calender${
      month && year ? `/?month=${month}&year=${year}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
}


export async function neutralQuery(data) {
  const token = localStorage.getItem("neutral");
  return request(`${config.BASE_URL}/neutrals/v1/contact-us`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function completeHearing(case_id, data) {
  const token = localStorage.getItem("neutral");
  return request(
    `${config.BASE_URL}/neutrals/v1/case_files/${case_id}/complete_hearing`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: data,
    }
  );
}



