import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import { neutral_authorizer } from "../utils/authorizer";

import NeutralDashboard from "../components/NeutralComponents/NeutralDashboard";
import YourCases from "../components/NeutralComponents/Case/YourCases";
import CaseDetail from "../components/NeutralComponents/Case/CaseDetail";
import AllHearings from "../components/common/AllHearings";
import ContactCoordinator from "../components/common/ContactCoordinator";
import AllComments from "../components/NeutralComponents/Case/Comments";



const PrivateRoutes = ({ logout, currMenu }) => {
   const PrivateRoute = ({
     component: Component,
     role,
     menu,
     render,
     ...rest
   }) => {
     return (
       <Route
         {...rest}
         render={(props) => {
           if (render) {
             return render;
           } else {
             return neutral_authorizer()
               ? (currMenu(menu), (<Component {...rest} />))
               : (logout(), (<Redirect to="/neutral/login" />));
           }
         }}
       />
     );
   };

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/neutral/dashboard"
        component={NeutralDashboard}
        menu={"neutral_dashboard"}
      />
      <PrivateRoute
        exact
        path="/neutral/cases/:id"
        component={CaseDetail}
        menu={"neutral_cases"}
      />
      <PrivateRoute
        exact
        path="/neutral/cases/:id/comments"
        component={AllComments}
        menu={"neutral_cases"}
      />
      <PrivateRoute
        exact
        path="/neutral/cases/:id/hearings"
        render={<AllHearings neutral />}
        menu={"neutral_cases"}
      />
      <PrivateRoute
        exact
        path="/neutral/cases"
        component={YourCases}
        menu={"neutral_cases"}
      />
      <PrivateRoute
        exact
        path="/neutral/contact_coordinator"
        component={ContactCoordinator}
      />

      <Route render={() => <Redirect to="/neutral/dashboard" />} />
    </Switch>
  );
};

export default PrivateRoutes;
